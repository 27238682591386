import { FC } from 'react';
import { useParams } from 'react-router';

import { Typography } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import { useQueryClient } from '@tanstack/react-query';

import Button from '@/components/button';
import Dialog from '@/components/dialog';
import { useDeleteKeywords } from '@/hooks/queries/keywords';
import useAlert from '@/hooks/useAlert';
import { Keywords } from '@/models/keywords';

interface DeleteKeywordsDialogProps {
  isOpen: boolean;
  onClose: () => void;
  selectedKeywords: GridRowSelectionModel;
  keywordsList: Keywords.Keyword[] | undefined;
}

const DeleteKeywordsDialog: FC<DeleteKeywordsDialogProps> = ({
  isOpen,
  onClose,
  selectedKeywords,
  keywordsList
}) => {
  const queryClient = useQueryClient();
  const id = Number(useParams().id);
  const alert = useAlert();

  const { mutate: deleteKeywords } = useDeleteKeywords(
    () => {
      const keywordsToDelete = keywordsList
        ? keywordsList
            .filter((keyword) => selectedKeywords.includes(keyword.id))
            .map((keyword) => keyword.value)
        : [];

      const alertMessage =
        selectedKeywords.length === 1
          ? `"${keywordsToDelete[0]}" has been successfully deleted`
          : `${selectedKeywords.length} keywords have been successfully deleted`;

      alert.info(alertMessage);
      queryClient.invalidateQueries({
        queryKey: ['keywordGroups'],
        refetchType: 'all'
      });
      queryClient.invalidateQueries({
        queryKey: ['keywordGroup', id],
        refetchType: 'all'
      });
      onClose();
    },
    () => {
      alert.error(`Some error occurred while deleting the keywords`);
      onClose();
    }
  );

  const handleConfirmDeleteKeyword = () => {
    if (!keywordsList) {
      alert.error('No keywords available to delete.');
      return;
    }

    const keywordsToDelete = keywordsList
      .filter((keyword) => selectedKeywords.includes(keyword.id))
      .map((keyword) => keyword.value);

    deleteKeywords({
      values: keywordsToDelete.join(','),
      categoryId: id
    });
  };

  return (
    <Dialog
      title="Delete Keyword"
      dialogContent={
        selectedKeywords.length === 1 ? (
          <>
            <Typography>
              Are you sure you want to delete &ldquo;
              {
                keywordsList?.find((item) => item.id === selectedKeywords[0])
                  ?.value
              }
              &ldquo;?
            </Typography>
            <Typography>
              You will not be able to recover this keyword.
            </Typography>
          </>
        ) : (
          <>
            <Typography>
              Are you sure you want to delete {selectedKeywords.length}{' '}
              keywords?
            </Typography>
            <Typography>You will not be able to recover these.</Typography>
          </>
        )
      }
      open={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} variant="text" text="cancel" />
          <Button
            onClick={handleConfirmDeleteKeyword}
            color="error"
            text="delete"
          />
        </>
      }
    />
  );
};

export default DeleteKeywordsDialog;
