import { useContext } from 'react';
import { useParams } from 'react-router';

import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Container, Tooltip } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';
import queryString from 'query-string';

import Button from '@/components/button';
import Header from '@/components/header';
import {
  ViolationContext,
  ViolationProvider
} from '@/contexts/ViolationContext';
import useArrowKeyNavigation from '@/hooks/useArrowKeyNavigation';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { URLParams } from '@/models/misc';
import getRoute from '@/utils/getRoute';
import NotFound from '@/views/misc/notFound';

import ViolationLayout from './ViolationLayout';

const ViolationContainer = () => {
  const navigate = useWorkspaceNavigate();
  const queryClient = useQueryClient();

  const { violationData, resetState, isError } = useContext(ViolationContext);

  const urlParams = queryString.parse(window.location.search);
  const previousPostId = violationData?.previousViolationId;
  const nextPostId = violationData?.nextViolationId;

  const handleClickPreviousPost = () => {
    resetState();
    if (previousPostId) {
      navigate(
        getRoute.violations.DETAIL(previousPostId, urlParams as URLParams)
      );
    }
  };

  const handleClickNextPost = () => {
    resetState();
    if (nextPostId) {
      navigate(getRoute.violations.DETAIL(nextPostId, urlParams as URLParams));
    }
  };

  useArrowKeyNavigation({
    onLeft: () => handleClickPreviousPost(),
    onRight: () => handleClickNextPost()
  });

  return (
    <Container maxWidth={false}>
      <Header
        sx={{ pt: 3 }}
        mb
        isSmallTitle
        actions={
          <>
            <Tooltip title="Left arrow key for previous post" arrow>
              <span>
                <Button
                  disabled={!previousPostId || isError}
                  onClick={handleClickPreviousPost}
                  size="small"
                  startIcon={<ArrowBack />}
                  text="Previous"
                />
              </span>
            </Tooltip>
            <Tooltip title="Right arrow key for next post" arrow>
              <span>
                <Button
                  disabled={!nextPostId || isError}
                  endIcon={<ArrowForward />}
                  onClick={handleClickNextPost}
                  size="small"
                  text="Next"
                />
              </span>
            </Tooltip>
          </>
        }
        onClickReturn={() => {
          queryClient.invalidateQueries({
            queryKey: ['posts'],
            refetchType: 'all'
          });
          navigate(getRoute.violations.LIST());
        }}
        showDivider
        title="Violations"
      />
      <ViolationLayout />
    </Container>
  );
};

const ViolationDetails = () => {
  const { violationId } = useParams();

  if (!violationId) return <NotFound />;

  return (
    <ViolationProvider
      postId={Number(violationId)}
      urlParams={queryString.parse(window.location.search)}
    >
      <ViolationContainer />
    </ViolationProvider>
  );
};

export default ViolationDetails;
