import { Typography } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import { useQueryClient } from '@tanstack/react-query';

import Button from '@/components/button';
import Dialog from '@/components/dialog/Dialog';
import { useDeleteCreator } from '@/hooks/queries/creators';
import useAlert from '@/hooks/useAlert';
import { Creator } from '@/models/creator';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  clearSelection: () => void;
  selected: GridRowSelectionModel;
  creatorsList: Array<Creator.Item>;
}

const DeleteCreators = ({
  isOpen,
  onClose,
  clearSelection,
  selected,
  creatorsList
}: Props) => {
  const queryClient = useQueryClient();
  const alert = useAlert();

  const { mutate: deleteCreator, isPending: isDeleting } = useDeleteCreator({
    onSuccess: () => {
      alert.success(`Creator(s) has been successfully deleted`);
      queryClient.invalidateQueries({ queryKey: ['creators'] });
      onClose();
      clearSelection();
    },
    onError: () => alert.error(`Some error occurred while deleting creators`)
  });

  const handleDeleteCreators = () => {
    deleteCreator({ ids: selected });
  };

  return (
    <Dialog
      title="Delete Creators"
      dialogContent={
        selected.length === 1 ? (
          <>
            <Typography>
              Are you sure you want to delete &ldquo;
              {creatorsList.find((creator) => creator.id === selected[0])?.name}
              &ldquo;?
            </Typography>
            <Typography>
              You will not be able to recover this creator.
            </Typography>
          </>
        ) : (
          <>
            <Typography>
              {`Are you sure you want to delete ${selected.length} creators?`}
            </Typography>
            <Typography>You will not be able to recover these.</Typography>
          </>
        )
      }
      open={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} variant="text" text="cancel" />
          <Button
            color="error"
            text="delete"
            onClick={handleDeleteCreators}
            loading={isDeleting}
          />
        </>
      }
    />
  );
};

export default DeleteCreators;
