import { useEffect, useState } from 'react';

import { Stack, Typography } from '@mui/material';

import Carousel from '@/components/carousel/Carousel';
import VideoPlayer from '@/components/video';
import { Posts } from '@/models/posts';

import PostIdentificationMarkers from './_partials/PostIdentificationMarkers';

interface MediaDisplayProps {
  data: Posts.Details; // TODO: Suport Violation Type
  showIdentificationMarkers?: boolean;
  markerItems?: Posts.MarkerItem[];
  videoPlaybackStart?: number;
  videoLastClick?: string | number;
  filter?: string;
  onMediaChange?: (index: number) => void;
  onVideoProgress?: (seconds: number) => void;
  onMarkersReset?: () => void;
}

const MediaDisplay = ({
  data,
  showIdentificationMarkers = false,
  markerItems = [],
  videoPlaybackStart,
  videoLastClick,
  filter = '',
  onMediaChange,
  onVideoProgress,
  onMarkersReset
}: MediaDisplayProps) => {
  const [dimensions, setDimensions] = useState({ width: 0, height: 0 });
  const [isPlaying, setIsPlaying] = useState(false);
  const [activeIndex, setActiveIndex] = useState(0);

  const handleLoad = () => {
    if (!data) return;
    const element = document.getElementById(data.media[activeIndex].mediaPath);
    if (element) {
      const { clientWidth: width, clientHeight: height } = element;
      if (width || height) setDimensions({ width, height });
    }
  };

  const handlePlay = () => {
    setIsPlaying(true);
    onMarkersReset?.();
  };

  const handleIndexChange = (index: number) => {
    setActiveIndex(index);
    onMediaChange?.(index);
    onMarkersReset?.();
  };

  useEffect(() => {
    if (videoPlaybackStart !== undefined && showIdentificationMarkers) {
      setIsPlaying(false);
    }
  }, [videoPlaybackStart, showIdentificationMarkers]);

  useEffect(() => {
    const handleResize = () => {
      // Force recalculation of dimensions
      handleLoad();
      // Force a re-render of markers by creating a new object reference
      setDimensions((prev) => ({ ...prev }));
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [activeIndex, data]); // Add dependencies to ensure correct media is targeted

  if (!data) return <Typography variant="body2">Data not available</Typography>;

  const renderMedia = (item: Posts.Media) => {
    const isVideo = item.mediaType === 'video';
    const isYoutubeContent =
      data.platform === 'youtube-video' ||
      data.platform === 'youtube-short' ||
      data.platform === 'youtube-livestream';

    if (isVideo) {
      return (
        <Stack
          alignItems="center"
          display={isYoutubeContent ? 'block' : 'flex'}
        >
          <Stack style={{ position: 'relative' }}>
            <VideoPlayer
              url={item.mediaPath}
              seek={videoPlaybackStart}
              lastClick={videoLastClick}
              platform={data.platform}
              onReady={handleLoad}
              onPlay={handlePlay}
              onProgress={(video) => onVideoProgress?.(video.playedSeconds)}
              playing={isPlaying}
            />
            {showIdentificationMarkers && (
              <PostIdentificationMarkers
                items={markerItems}
                dimensions={dimensions}
              />
            )}
          </Stack>
        </Stack>
      );
    }

    return (
      <Stack alignItems="center" display="block">
        <Stack style={{ position: 'relative', justifyContent: 'center' }}>
          <div
            id="image-container"
            style={{
              position: 'relative',
              display: 'flex',
              justifyContent: 'center',
              height: 'calc(100vh - 213px)'
            }}
          >
            <img
              id={item.mediaPath}
              src={item.mediaPath}
              alt={data.title}
              style={{
                filter,
                objectFit: 'contain'
              }}
              onLoad={handleLoad}
            />
          </div>
          {showIdentificationMarkers && (
            <PostIdentificationMarkers
              items={markerItems}
              dimensions={dimensions}
            />
          )}
        </Stack>
      </Stack>
    );
  };

  return data.media.length > 1 ? (
    <Carousel
      cycleNavigation={false}
      autoPlay={false}
      activeIndex={activeIndex}
      onChangeVisibleItem={handleIndexChange}
      items={data.media.map((_, idx) => (
        <div
          key={`${data.media[idx].mediaPath}${data.title}${idx}`}
          style={{ display: activeIndex === idx ? 'block' : 'none' }}
        >
          {activeIndex === idx && renderMedia(data.media[idx])}
          {/* Render only active media */}
        </div>
      ))}
      id={Number(data.id)}
      sx={{
        height: 'calc(100vh - 252px)'
      }}
    />
  ) : (
    renderMedia(data.media[activeIndex])
  );
};

export default MediaDisplay;
