import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { Stack } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';

import AutocompleteCreators from '@/components/autocompleteCreators/AutocompleteCreators';
import Button from '@/components/button/Button';
import Dialog from '@/components/dialog/Dialog';
import { useAddCreatorsToGroup } from '@/hooks/queries/creatorGroups';
import { useFetchCreatorsAutocomplete } from '@/hooks/queries/creators';
import useAlert from '@/hooks/useAlert';
import { CreatorsAutocomplete } from '@/models/creator';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const AddCreatorToGroup = ({ isOpen, onClose }: Props) => {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const id = Number(useParams().id);

  const [selectedCreators, setSelectedCreators] = useState<
    CreatorsAutocomplete.Creator[]
  >([]);

  const { data } = useFetchCreatorsAutocomplete({
    params: {
      excludeGroupId: id,
      groups: false
    }
  });

  const { mutate: addCreators, isPending: isSubmitting } =
    useAddCreatorsToGroup({
      id,
      onSuccess: () => {
        alert.success(`${creatorsLabel} successfully added to the group!`);
        queryClient.invalidateQueries({
          queryKey: ['creatorGroup'],
          refetchType: 'all'
        });
        queryClient.invalidateQueries({
          queryKey: ['creatorsAutocomplete'],
          refetchType: 'all'
        });
        onClose();
      },
      onError: () =>
        alert.error(
          `Some error occurred while adding the ${creatorsLabel.toLowerCase()}`
        )
    });

  const handleSelectCreator = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: CreatorsAutocomplete.Creator[]
  ) => {
    setSelectedCreators(value);
  };

  const handleSubmit = () => {
    const creators = selectedCreators.flatMap((creator) => creator.id);
    addCreators({ creators });
  };

  const creatorsLabel = selectedCreators.length === 1 ? 'Creator' : 'Creators';

  return (
    <Dialog
      maxWidth="sm"
      fullWidth
      title="Add Creators"
      open={isOpen}
      onClose={onClose}
      dialogContent={
        <Stack marginTop={1}>
          <AutocompleteCreators
            value={selectedCreators}
            onChange={handleSelectCreator}
            creators={data?.creators || []}
          />
        </Stack>
      }
      actions={
        <>
          <Button onClick={onClose} variant="text" text="cancel" />
          <Button
            onClick={handleSubmit}
            text="Add"
            disabled={selectedCreators.length === 0}
            loading={isSubmitting}
          />
        </>
      }
    />
  );
};

export default AddCreatorToGroup;
