import { useMutation, useQuery } from '@tanstack/react-query';

import {
  createCompliance,
  deleteCompliance,
  getPost,
  getPosts,
  updateCompliance
} from '@/api/posts';
import { Posts } from '@/models/posts';

import { Callback } from '.';

type PostsProps = {
  params: Posts.ListParams;
  onError?: (_error: unknown) => void;
  enabled?: boolean;
  cacheTime?: number;
};

export const useFetchPosts = ({ params, enabled, cacheTime }: PostsProps) =>
  useQuery({
    queryKey: ['posts', params],
    queryFn: async () => {
      const result = await getPosts(params);
      return result.data;
    },
    enabled,
    gcTime: cacheTime
  });

export const useFetchPost = ({
  id,
  params,
  onSuccess,
  onError,
  enabled,
  staleTime
}: {
  id: number;
  params?: Posts.DetailParams;
  onSuccess?: (_data: Posts.Details) => void;
  onError?: (_error: unknown) => void;
  enabled?: boolean;
  staleTime?: number;
}) =>
  useQuery({
    queryKey: ['post', id, ...(params ? [params] : [])],
    queryFn: async () => {
      const { data } = await getPost(id, params ?? {});
      return data;
    },
    enabled,
    staleTime,
    meta: { onSuccess, onError }
  });

export const useCreateCompliance = (onSuccess: Callback, onError: Callback) =>
  useMutation({
    mutationKey: ['createCompliance'],
    mutationFn: createCompliance,
    onSuccess,
    onError
  });

export const useUpdateCompliance = (onSuccess: Callback, onError: Callback) =>
  useMutation({
    mutationKey: ['updateCompliance'],
    mutationFn: updateCompliance,
    onSuccess,
    onError
  });

export const useDeleteCompliance = (
  onSuccess: Callback,
  onError: Callback,
  onSettled: Callback
) =>
  useMutation({
    mutationKey: ['deleteCompliance'],
    mutationFn: deleteCompliance,
    onSuccess,
    onError,
    onSettled
  });
