import { useEffect } from 'react';
import 'react-perfect-scrollbar/dist/css/styles.css';

import { CssBaseline } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { LicenseInfo } from '@mui/x-license-pro';

import { ClickAnalyticsPlugin } from '@microsoft/applicationinsights-clickanalytics-js';
import {
  AppInsightsErrorBoundary,
  ReactPlugin
} from '@microsoft/applicationinsights-react-js';
import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

import { useAppDispatch, useAppSelector } from '@/hooks/redux';
import LocalStorageService from '@/services/LocalStorageService';
import { getUser } from '@/store/userSlice';

import Router from './router';
import themeDefault from './themes/themeDefault';
import Error from './views/misc/error';

// Application Insights Configuration
const reactPlugin = new ReactPlugin();
// *** Add the Click Analytics plug-in. ***
const clickPluginInstance = new ClickAnalyticsPlugin();
const clickPluginConfig = {
  autoCapture: true
};

const appInsights = new ApplicationInsights({
  config: {
    connectionString:
      process.env.REACT_APP_APPLICATION_INSIGHTS_CONNECTION || '',
    enableAutoRouteTracking: true,
    enableCorsCorrelation: true,
    correlationHeaderDomains: [
      'app.influencermonitor.com',
      'staging.app.influencermonitor.com'
    ],
    // *** Add the Click Analytics plug-in. ***
    extensions: [reactPlugin, clickPluginInstance],
    extensionConfig: {
      // *** Add the Click Analytics plug-in. ***
      [clickPluginInstance.identifier]: clickPluginConfig
    }
  }
});
appInsights.loadAppInsights();
appInsights.trackPageView();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 5 * 60 * 1000
    }
  }
});

LicenseInfo.setLicenseKey(process.env.REACT_APP_MUI_LICENSE_KEY || '');

const App = () => {
  const dispatch = useAppDispatch();
  const user = useAppSelector((state) => state.user.user);

  useEffect(() => {
    if (user) {
      appInsights.setAuthenticatedUserContext(user.id.toString());
    }
  }, [user]);

  useEffect(() => {
    if (LocalStorageService.getItem('token')) dispatch(getUser());
  }, []);

  return (
    <AppInsightsErrorBoundary
      onError={() => (
        <Error message="An unexpected error occurred in the application" />
      )}
      appInsights={reactPlugin}
    >
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={themeDefault}>
          <CssBaseline />
          <Router />
        </ThemeProvider>
        <ReactQueryDevtools initialIsOpen={false} />
      </QueryClientProvider>
    </AppInsightsErrorBoundary>
  );
};

export default App;
