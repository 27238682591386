import { useContext } from 'react';

import { Grid, Skeleton, Stack, styled } from '@mui/material';

import Post from '@/components/post/Post';
import { ViolationContext } from '@/contexts/ViolationContext';
import shadows from '@/themes/theme/shadows';
import Error from '@/views/misc/error';

const ScrollableStack = styled(Stack)(({ theme }) => ({
  display: 'block',
  maxHeight: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: '16px',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.background.paper,
  boxShadow: shadows.cardDefault,
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',

  // More prominent scrollbar
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.paper}`,

  // Enhanced spacing and borders between components
  '& > *': {
    border: `1px solid ${theme.palette.divider}`,
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out'
  }
}));

const ViolationSkeleton = () => (
  <Grid
    container
    spacing={2}
    sx={{
      height: 'calc(100vh - 108px)' // Full viewport height minus any header offset
    }}
  >
    {/* Left Grid */}
    <Grid item xs={12} md={8} sx={{ height: '100%' }}>
      <Stack gap={2} sx={{ height: '100%' }}>
        <Skeleton variant="rectangular" height={75.25} />
        <Skeleton variant="rectangular" height="calc(100% - 72px)" />
      </Stack>
    </Grid>

    {/* Right Grid */}
    <Grid item xs={12} md={4} sx={{ height: '100%' }}>
      <Skeleton variant="rectangular" height="100%" />
    </Grid>
  </Grid>
);

const ViolationLayout = () => {
  const { postData, violationData, isLoading, setActiveIndex } =
    useContext(ViolationContext);

  if (isLoading) return <ViolationSkeleton />;

  if (!postData) return <Error message="Failed to load post" />;

  return (
    <Grid
      container
      spacing={2}
      sx={{
        height: 'calc(100vh - 108px)' // Full viewport height minus any header offset
      }}
    >
      {/* Left Grid */}
      <Grid item xs={12} md={8} sx={{ height: '100%' }}>
        <Post postData={postData} setActiveIndex={setActiveIndex} />
      </Grid>

      {/* Right Grid */}
      <Grid item xs={12} md={4} sx={{ height: '100%' }}>
        <ScrollableStack>
          {!violationData && <>No violation data</>}
        </ScrollableStack>
      </Grid>
    </Grid>
  );
};

export default ViolationLayout;
