import { useState } from 'react';

import { TextField, Stack, Typography, Chip } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';

import AutocompleteComponent from '@/components/autocomplete/Autocomplete';
import Button from '@/components/button';
import Dialog from '@/components/dialog/Dialog';
import InstagramIcon from '@/components/icons/InstagramIcon';
import TikTokIcon from '@/components/icons/TikTokIcon';
import YoutubeIcon from '@/components/icons/YoutubeIcon';
import { useCreateCreator } from '@/hooks/queries/creators';
import useAlert from '@/hooks/useAlert';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { Creator } from '@/models/creator';
import getRoute from '@/utils/getRoute/getRoute';

const initialAccounts = {
  youtube: [],
  instagram: [],
  tiktok: []
};

const initialErrors = {
  youtube: '',
  instagram: '',
  tiktok: '',
  name: '',
  accounts: ''
};

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const CreateCreator = ({ isOpen, onClose }: Props) => {
  const queryClient = useQueryClient();
  const navigate = useWorkspaceNavigate();
  const alert = useAlert();

  const [creatorName, setCreatorName] = useState('');
  const [accounts, setAccounts] =
    useState<Creator.Create['accounts']>(initialAccounts);
  const [errors, setErrors] = useState(initialErrors);

  const { mutate: createCreator, isPending } = useCreateCreator({
    onSuccess: ({ data }) => {
      const { id, name } = data;
      queryClient.invalidateQueries({ queryKey: ['creators'] });
      queryClient.invalidateQueries({
        queryKey: ['creatorsAutocomplete'],
        refetchType: 'all'
      });

      alert.success(
        `"${name}" has been successfully added as a creator to the platform.`,
        <Button
          variant="text"
          size="small"
          onClick={() => navigate(getRoute.creators.DETAIL(id))}
          text="open"
        />
      );
      onClose();
    },
    onError: ({ response }) => {
      if (response.status === 409) {
        setErrors({ ...errors, name: 'Creator already exists' });
        return;
      }
      if (response.status === 400) {
        alert.error(response.data.message);
        return;
      }
      alert.error('Oops, something went wrong.');
    }
  });

  const isAccountValid = (
    account: string,
    platform: Creator.PlatformOptions
  ) => {
    if (
      platform === 'youtube' &&
      !(account.startsWith('@') || account.startsWith('UC'))
    ) {
      return false;
    }
    if (platform !== 'youtube' && !account.startsWith('@')) {
      return false;
    }
    return account.length >= 3;
  };

  const handleOnChange = (
    reason: string,
    details: any,
    field: Creator.PlatformOptions
  ) => {
    switch (reason) {
      case 'createOption':
        {
          const newValue = details.option;

          if (!isAccountValid(newValue, field)) {
            setErrors({
              ...errors,
              [field]:
                field === 'youtube'
                  ? `Account must start with '@' or 'UC' and be at least 3 characters long`
                  : `Account must start with '@' and be at least 3 characters long`
            });
            return;
          }

          if (!accounts[field].includes(newValue)) {
            setAccounts({
              ...accounts,
              [field]: [...accounts[field], newValue]
            });
            setErrors(initialErrors);
          } else {
            setErrors({ ...errors, [field]: `${newValue} already exists` });
          }
        }
        break;
      case 'removeOption':
        {
          const index = accounts[field].findIndex((i) => i === details.option);

          const copyArray = accounts[field];
          if (index > -1) {
            copyArray.splice(index, 1);
          }

          setErrors(initialErrors);
          setAccounts({ ...accounts, [field]: copyArray });
        }
        break;
      case 'clear':
        setErrors(initialErrors);
        setAccounts({ ...accounts, [field]: [] });
        break;
      default:
    }
  };

  const handleSubmitCreator = () => {
    const emptyAccounts =
      accounts.instagram.length === 0 &&
      accounts.youtube.length === 0 &&
      accounts.tiktok.length === 0;

    if (emptyAccounts) {
      setErrors({ ...errors, accounts: 'Please add at least one account' });
      return;
    }

    // Remove '@' at the beginning of the word for Instagram and TikTok
    const modifiedAccounts = {
      youtube: accounts.youtube,
      instagram: accounts.instagram.map((account) =>
        account.startsWith('@') ? account.slice(1) : account
      ),
      tiktok: accounts.tiktok.map((account) =>
        account.startsWith('@') ? account.slice(1) : account
      )
    };

    const creator = {
      name: creatorName.trim(),
      accounts: modifiedAccounts
    };
    createCreator(creator);
  };

  return (
    <Dialog
      open={isOpen}
      title="Add creator"
      fullWidth
      maxWidth="sm"
      onClose={onClose}
      dialogContent={
        <>
          <TextField
            fullWidth
            label="Creator Name"
            variant="standard"
            onChange={(e) => setCreatorName(e.target.value)}
            error={Boolean(errors.name)}
            helperText={errors.name}
          />
          <Stack mt={4} mb={2}>
            <Typography>Add Accounts</Typography>
            <Typography variant="caption" color="textSecondary">
              Accounts must start with &quot;@&quot; or &quot;UC&quot; for
              Youtube
            </Typography>
          </Stack>
          <Stack gap={2}>
            <AutocompleteComponent
              freeSolo
              getOptionLabel={(t) => t}
              clearOnBlur
              options={[]}
              value={accounts.youtube}
              onChange={(event, newValue, reason, details) => {
                handleOnChange(reason, details, 'youtube');
              }}
              onBlur={({
                target: { value }
              }: React.FocusEvent<HTMLInputElement>) => {
                if (value === '') return;
                handleOnChange('createOption', { option: value }, 'youtube');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="medium"
                  error={Boolean(errors.youtube)}
                  helperText={errors.youtube}
                  label={
                    <Stack direction="row" gap={1}>
                      <YoutubeIcon />
                      Youtube
                    </Stack>
                  }
                  placeholder={
                    accounts.youtube.length > 0 ? '' : 'Add Youtube account'
                  }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((tag, index) => (
                  <Chip
                    color="primary"
                    size="small"
                    {...getTagProps({ index })}
                    label={tag}
                    key={`chip__${tag}`}
                  />
                ))
              }
            />
            <AutocompleteComponent
              freeSolo
              getOptionLabel={(t) => t}
              clearOnBlur
              options={[]}
              value={accounts.instagram}
              onChange={(event, newValue, reason, details) => {
                handleOnChange(reason, details, 'instagram');
              }}
              onBlur={({
                target: { value }
              }: React.FocusEvent<HTMLInputElement>) => {
                if (value === '') return;
                handleOnChange('createOption', { option: value }, 'instagram');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="medium"
                  error={Boolean(errors.instagram)}
                  helperText={errors.instagram}
                  label={
                    <Stack direction="row" gap={1}>
                      <InstagramIcon />
                      Instagram
                    </Stack>
                  }
                  placeholder={
                    accounts.instagram.length > 0 ? '' : 'Add Instagram account'
                  }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((tag, index) => (
                  <Chip
                    color="primary"
                    size="small"
                    {...getTagProps({ index })}
                    label={tag}
                    key={`chip__${tag}`}
                  />
                ))
              }
            />
            <AutocompleteComponent
              freeSolo
              getOptionLabel={(t) => t}
              clearOnBlur
              options={[]}
              value={accounts.tiktok}
              onChange={(event, newValue, reason, details) => {
                handleOnChange(reason, details, 'tiktok');
              }}
              onBlur={({
                target: { value }
              }: React.FocusEvent<HTMLInputElement>) => {
                if (value === '') return;
                handleOnChange('createOption', { option: value }, 'tiktok');
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  size="medium"
                  error={Boolean(errors.tiktok)}
                  helperText={errors.tiktok}
                  label={
                    <Stack direction="row" gap={1}>
                      <TikTokIcon />
                      TikTok
                    </Stack>
                  }
                  placeholder={
                    accounts.tiktok.length > 0 ? '' : 'Add TikTok account'
                  }
                />
              )}
              renderTags={(value, getTagProps) =>
                value.map((tag, index) => (
                  <Chip
                    color="primary"
                    size="small"
                    {...getTagProps({ index })}
                    label={tag}
                    key={`chip__${tag}`}
                  />
                ))
              }
            />
            {errors.accounts && (
              <Typography color="error">{errors.accounts}</Typography>
            )}
          </Stack>
        </>
      }
      actions={
        <>
          <Button variant="text" text="cancel" onClick={onClose} />
          <Button
            text="submit"
            onClick={handleSubmitCreator}
            loading={isPending}
          />
        </>
      }
    />
  );
};

export default CreateCreator;
