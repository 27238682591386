import { AxiosPromise } from 'axios';

import { Workspace } from '@/models/workspace';

import { api } from '.';

export const getWorkspaces = (): AxiosPromise<Workspace[]> =>
  api.get(`v2/workspaces`);

export default getWorkspaces;
