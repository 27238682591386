const FILTERS_TO_RENDER = {
  type: [
    { name: 'notRelevant', label: 'Not relevant' },
    { name: 'advertisement', label: 'Advertisement' },
    { name: 'questionable', label: 'Questionable' },
    { name: 'organic', label: 'Organic' }
  ],
  compliance: [
    { name: 'compliant', label: 'Compliant' },
    { name: 'questionable', label: 'Questionable' },
    { name: 'violation', label: 'Violation' }
  ],
  platform: {
    youtube: [
      { name: 'youtube-video', label: 'Videos' },
      { name: 'youtube-short', label: 'Shorts' }
    ],
    instagram: [
      { name: 'instagram-post', label: 'Posts' },
      { name: 'instagram-reel', label: 'Reels' },
      { name: 'instagram-story', label: 'Stories' }
    ],
    tiktok: [{ name: 'tiktok-post', label: 'TikTok' }]
  }
};

export default FILTERS_TO_RENDER;
