import { useState } from 'react';

import { InfoOutlined } from '@mui/icons-material';
import { IconButton, Stack, TextField, Typography } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';

import AutocompleteCreators from '@/components/autocompleteCreators';
import Button from '@/components/button';
import Dialog from '@/components/dialog';
import { useCreateCreatorGroup } from '@/hooks/queries/creatorGroups';
import { useFetchCreatorsAutocomplete } from '@/hooks/queries/creators';
import useAlert from '@/hooks/useAlert';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { CreatorsAutocomplete } from '@/models/creator';
import getRoute from '@/utils/getRoute/getRoute';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const initialErrors = {
  invalidGroupName: '',
  noCreatorsSelected: ''
};

const CreateCreatorGroup = ({ isOpen, onClose }: Props) => {
  const alert = useAlert();
  const navigate = useWorkspaceNavigate();
  const queryClient = useQueryClient();

  const [groupName, setGroupName] = useState('');
  const [creators, setCreators] = useState<CreatorsAutocomplete.Creator[]>([]);
  const [onBoardingInfo, setOnBoardingInfo] = useState(false);
  const [errors, setErrors] = useState(initialErrors);

  const { data: creatorsAutocomplete } = useFetchCreatorsAutocomplete({
    params: { groups: false }
  });

  const { mutate: createGroup, isPending: isCreating } = useCreateCreatorGroup({
    onSuccess: ({ data }) => {
      const { id, name } = data;
      queryClient.invalidateQueries({ queryKey: ['creatorGroups'] });
      queryClient.invalidateQueries({
        queryKey: ['posts'],
        refetchType: 'all'
      });
      queryClient.invalidateQueries({
        queryKey: ['creatorsAutocomplete'],
        refetchType: 'all'
      });

      alert.success(
        `"${name}" has been successfully created.`,
        <Button
          variant="text"
          size="small"
          onClick={() => navigate(getRoute.creators.DETAIL(id))}
          text="open"
        />
      );
      handleCloseCreateGroup();
    },
    onError: ({ response }) => {
      if (response.status === 409) {
        setErrors({
          ...errors,
          invalidGroupName: 'Creator Group already exists'
        });
        return;
      }
      alert.error('Oops, something went wrong.');
    }
  });

  const handleSubmitGroup = () => {
    if (!groupName) {
      setErrors({ ...errors, invalidGroupName: 'Please input a group title' });
      return;
    }
    if (creators.length === 0) {
      setErrors({
        ...errors,
        noCreatorsSelected: 'Please select at least one creator'
      });
      return;
    }
    createGroup({
      name: groupName,
      creators: creators.map((creator) => creator.id)
    });
  };

  const handleCloseCreateGroup = () => {
    onClose();
    setGroupName('');
    setCreators([]);
    setErrors(initialErrors);
  };

  const handleSelectCreator = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: CreatorsAutocomplete.Creator[]
  ) => {
    setCreators(value);
    setErrors({ ...errors, noCreatorsSelected: '' });
  };

  return (
    <Dialog
      open={isOpen}
      title="Add a new creator group"
      fullWidth
      maxWidth="md"
      titleIcon={
        !onBoardingInfo ? (
          <IconButton onClick={() => setOnBoardingInfo(true)}>
            <InfoOutlined color="action" />
          </IconButton>
        ) : (
          <></>
        )
      }
      subTitle={
        onBoardingInfo
          ? 'A creators group enables you to effortlessly include the same group of creators in multiple evaluations.'
          : ''
      }
      dialogContent={
        <Stack marginTop={1} gap={3}>
          <TextField
            fullWidth
            label="Group Title"
            placeholder="Input text"
            onChange={(e) => {
              setGroupName(e.target.value);
              setErrors({ ...errors, invalidGroupName: '' });
            }}
            error={Boolean(errors.invalidGroupName)}
            helperText={errors.invalidGroupName}
          />
          <AutocompleteCreators
            value={creators}
            onChange={handleSelectCreator}
            creators={creatorsAutocomplete?.creators || []}
          />
          {errors.noCreatorsSelected && (
            <Typography color="error">{errors.noCreatorsSelected}</Typography>
          )}
        </Stack>
      }
      actions={
        <>
          <Button
            variant="text"
            text="cancel"
            onClick={handleCloseCreateGroup}
          />
          <Button
            text="submit"
            onClick={handleSubmitGroup}
            loading={isCreating}
          />
        </>
      }
    />
  );
};

export default CreateCreatorGroup;
