import { useQuery } from '@tanstack/react-query';

import { getViolations } from '@/api/violations';
import { Violations } from '@/models/violations';

type UseViolationsParams = {
  params?: Violations.ViolationParams;
  onSuccess?: (data: any) => void;
  onError?: (error: any) => void;
  enabled?: boolean;
};

export const useViolations = ({
  params,
  onSuccess,
  onError,
  enabled = true
}: UseViolationsParams) =>
  useQuery({
    queryKey: ['violations', params],
    queryFn: async () => {
      const result = await getViolations(params);
      return result.data;
    },
    enabled,
    meta: {
      onSuccess,
      onError
    }
  });
