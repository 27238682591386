import { useParams } from 'react-router-dom';

import { Stack } from '@mui/material';

import Page from '@/components/Page';
import Header from '@/components/header';
import Loading from '@/components/loading';
import { useFetchEvaluation } from '@/hooks/queries/evaluations';
import { getPathWithLocalParams } from '@/hooks/urlParams';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import getRoute from '@/utils/getRoute';
import Error from '@/views/misc/error';

import EvaluationCriteria from './_partials/EvaluationCriteria';
import Body from './body/Body';

const EvaluationDetails = () => {
  const navigate = useWorkspaceNavigate();
  const evaluationId = Number(useParams().evaluationId);

  const { data, isLoading, isError, isFetching } = useFetchEvaluation({
    id: evaluationId
  });

  if (isError)
    return (
      <Page>
        <Error message="Failed to get evaluation details" />
      </Page>
    );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <Page
      title="Evaluation Details"
      header={
        <Header
          isSmallHeader
          breadcrumbsList={[
            {
              name: 'Evaluations',
              onClick: () =>
                navigate(getPathWithLocalParams(getRoute.evaluations.LIST()))
            }
          ]}
          onClickReturn={() =>
            navigate(getPathWithLocalParams(getRoute.evaluations.LIST()))
          }
          title={data?.title}
        />
      }
    >
      <Stack gap={2.5}>
        <EvaluationCriteria isFetching={isFetching} />
        <Body />
      </Stack>
    </Page>
  );
};

export default EvaluationDetails;
