import { useState, useMemo } from 'react';

import { Group, Person } from '@mui/icons-material';
import {
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import Page from '@/components/Page';
import Button from '@/components/button/Button';
import DataTable from '@/components/dataTable/DataTable';
import NoRowsOverlay from '@/components/dataTable/customNoRowsOverlay/NoRowsOverlay';
import OnBoardingOverlay from '@/components/dataTable/customNoRowsOverlay/OnBoardingOverlay';
import Header from '@/components/header/Header';
import Toolbar from '@/components/toolbar/Toolbar';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants/table';
import { useFetchCreatorGroups } from '@/hooks/queries/creatorGroups';
import { useDebounce } from '@/hooks/useDebounce';
import useTableParams from '@/hooks/useTableParams';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { getPrettyDate } from '@/utils/datesAndTime';
import getRoute from '@/utils/getRoute/getRoute';
import Error from '@/views/misc/error';

import CreateCreatorGroup from '../create/CreateCreatorGroup';
import DeleteCreatorGroup from '../delete/DeleteCreatorGroup';

const defaultParams = {
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE,
  sort: undefined,
  order: undefined,
  name: undefined
};

const GroupsOnBoardingOverlay = () => (
  <OnBoardingOverlay
    icon={<Group color="action" sx={{ width: 40, height: 40 }} />}
    title="Start list of Creators Groups"
    text="A creators group enables you to effortlessly include the same group of creators in multiple evaluations."
  />
);

const CreatorGroupsList = () => {
  const theme = useTheme();
  const navigate = useWorkspaceNavigate();
  const { params, handlers } = useTableParams(
    getRoute.creatorGroups.LIST(),
    defaultParams
  );
  const deferredGroupsValue = useDebounce(params.name, 500);

  const [isCreateGroupOpen, setIsCreateGroupOpen] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);

  const {
    data: groups,
    isLoading: isLoadingGroups,
    isError: isErrorGroups
  } = useFetchCreatorGroups({
    params: {
      ...params,
      name: deferredGroupsValue ? String(deferredGroupsValue) : undefined
    }
  });

  const [selectedGroups, setSelectedGroups] = useState<GridRowSelectionModel>(
    []
  );

  const columns = useMemo(
    () => [
      {
        sortable: false,
        field: 'title',
        headerName: 'Title',
        flex: 1,
        width: 160,
        renderCell: (data: any) => {
          const { name } = data.row;

          return (
            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {name}
            </Typography>
          );
        }
      },
      {
        field: 'creators',
        headerName: 'Creators',
        flex: 1,
        width: 160,
        renderCell: (data: any) => {
          const { totalItems: totalCreators } = data.row;

          return <Typography variant="body2">{totalCreators}</Typography>;
        }
      },
      {
        field: 'updatedAt',
        headerName: 'Last edited',
        flex: 1,
        width: 140,
        renderCell: (data: any) => {
          const { updatedAt, createdAt } = data.row;

          return (
            <Typography>{getPrettyDate(updatedAt || createdAt)}</Typography>
          );
        }
      }
    ],
    [groups?.results]
  );

  let emptyStateCreatorGroups = GroupsOnBoardingOverlay;
  if (params.name !== undefined)
    emptyStateCreatorGroups = () => <NoRowsOverlay />;

  if (isErrorGroups)
    return (
      <Page>
        <Error message="Failed to get creator groups" />
      </Page>
    );

  return (
    <>
      <Page
        header={
          <Header
            title="Creator Groups"
            actions={
              <>
                <ToggleButtonGroup
                  value="creatorGroups"
                  size="small"
                  sx={{
                    backgroundColor: theme.palette.background.paper
                  }}
                >
                  <ToggleButton
                    value="creators"
                    onClick={() => navigate('/creators')}
                  >
                    <Person />
                  </ToggleButton>
                  <ToggleButton
                    onClick={() => navigate('/creator-groups')}
                    value="creatorGroups"
                  >
                    <Group />
                  </ToggleButton>
                </ToggleButtonGroup>
                <Button
                  text="add group"
                  onClick={() => setIsCreateGroupOpen(true)}
                />
              </>
            }
          />
        }
        toolbar={
          <Toolbar
            isDeleteEnabled={selectedGroups.length > 0}
            onClickDelete={() => setIsOpenDeleteDialog(true)}
            onChangeSearch={handlers.onChangeSearch}
            showDeleteButton
            showSearchInput
            searchValue={params.name ? String(params.name) : ''}
            totalItems={groups?.totalItems || 0}
          />
        }
        title="Creator Groups"
      >
        <DataTable
          columns={columns}
          rowHeight={80}
          rows={groups?.results || []}
          rowCount={groups?.totalItems}
          checkboxSelection
          disableColumnMenu
          loading={isLoadingGroups}
          filterMode="server"
          paginationMode="server"
          sortingMode="server"
          onChangePageSize={handlers.onChangePageSize}
          onChangePage={handlers.onChangePage}
          onSortModelChange={handlers.onSortModelChange}
          onRowClick={(row) => navigate(getRoute.creatorGroups.DETAIL(row.id))}
          sort={params.sort}
          order={params.order}
          page={params.page}
          size={params.size}
          rowSelectionModel={selectedGroups}
          onRowSelectionModelChange={setSelectedGroups}
          noRowsOverlay={emptyStateCreatorGroups}
        />
      </Page>
      {isCreateGroupOpen && (
        <CreateCreatorGroup
          isOpen={isCreateGroupOpen}
          onClose={() => setIsCreateGroupOpen(false)}
        />
      )}
      {isOpenDeleteDialog && (
        <DeleteCreatorGroup
          isOpen={isOpenDeleteDialog}
          onClose={() => setIsOpenDeleteDialog(false)}
          selected={selectedGroups}
          clearSelection={() => {
            setSelectedGroups([]);
          }}
          groupsList={groups?.results || []}
        />
      )}
    </>
  );
};

export default CreatorGroupsList;
