import { useMutation, useQuery } from '@tanstack/react-query';

import {
  createKeywordGroup,
  createKeywords,
  deleteKeywordGroups,
  deleteKeywords,
  getKeywordGroupById,
  getKeywordGroups,
  getKeywordsAutocomplete
} from '@/api/keywords';
import { Keywords } from '@/models/keywords';

import { Callback } from '.';

export const useFetchKeywordGroups = () =>
  useQuery({
    queryKey: ['keywordGroups'],
    queryFn: async () => {
      const result = await getKeywordGroups();

      return result.data.map((element: any) => ({
        ...element,
        type: 'Keyword group'
      }));
    }
  });

export const useCreateKeywordGroup = (onSuccess: Callback, onError: Callback) =>
  useMutation({
    mutationFn: createKeywordGroup,
    onSuccess,
    onError
  });

export const useDeleteKeywordGroups = (
  onSuccess: Callback,
  onError: Callback
) =>
  useMutation({
    mutationFn: deleteKeywordGroups,
    onSuccess,
    onError
  });

export const useFetchKeywordsAutocomplete = (val: string) =>
  useQuery({
    queryKey: ['keywordsAutocomplete', val],
    queryFn: async () => {
      const result = await getKeywordsAutocomplete(val);

      const keywords = result.data.map(
        ({ value }: Keywords.KeywordSuggestion) => value
      );
      const constructedKeywords = keywords.map(
        (keyword: Keywords.KeywordSuggestion) => ({
          title: keyword,
          type: 'Keyword'
        })
      );

      return constructedKeywords;
    }
  });

export const useFetchKeywordGroupById = (id: number) =>
  useQuery({
    queryKey: ['keywordGroup', id],
    queryFn: async () => {
      const result = await getKeywordGroupById(id);
      return result.data;
    }
  });

export const useDeleteKeywords = (onSuccess: Callback, onError: Callback) =>
  useMutation({
    mutationFn: deleteKeywords,
    onSuccess,
    onError
  });

export const useCreateKeywords = (onSuccess: Callback, onError: Callback) =>
  useMutation({
    mutationFn: createKeywords,
    onSuccess,
    onError
  });
