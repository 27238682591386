import { Link as RouterLink } from 'react-router-dom';

import { Link, Stack, TextField, Typography } from '@mui/material';

import { Formik } from 'formik';
import * as Yup from 'yup';

import Button from '@/components/button';
import { useResetPassword } from '@/hooks/queries/auth';

const RecoverForm = () => {
  const {
    mutate: resetPassword,
    isPending,
    isSuccess,
    isError
  } = useResetPassword();

  if (isSuccess)
    return (
      <Typography variant="body2" textAlign="center">
        Password reset, check your email.
      </Typography>
    );

  return (
    <>
      {isError ? (
        <Typography variant="body2" color="red">
          There was an issue with your request.
        </Typography>
      ) : null}
      <Formik
        initialValues={{
          email: ''
        }}
        validationSchema={Yup.object().shape({
          email: Yup.string()
            .email('Must be a valid email')
            .max(255)
            .required('Email is required')
        })}
        onSubmit={async ({ email }) => {
          resetPassword(email);
        }}
      >
        {({
          errors,
          handleBlur,
          handleChange,
          handleSubmit,
          touched,
          values
        }) => (
          <form onSubmit={handleSubmit} style={{ marginBottom: '16px' }}>
            <Stack gap={2}>
              <TextField
                error={Boolean(touched.email && errors.email)}
                fullWidth
                helperText={touched.email && errors.email}
                label="Business Email"
                margin="normal"
                name="email"
                onBlur={handleBlur}
                onChange={handleChange}
                type="email"
                value={values.email}
                variant="outlined"
                disabled={isPending}
                sx={{ margin: 0 }}
              />
              <Button
                fullWidth
                size="large"
                type="submit"
                text="Recover Password"
                loading={isPending}
              />
            </Stack>
          </form>
        )}
      </Formik>
      <Typography variant="body2">
        Already have an account?{' '}
        <Link
          component={RouterLink}
          to="/auth/login"
          color="secondary"
          variant="body2"
          sx={{
            textDecoration: 'underline'
          }}
        >
          Login Here
        </Link>
      </Typography>
    </>
  );
};

export default RecoverForm;
