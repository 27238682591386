import { createContext, Dispatch, SetStateAction, useState } from 'react';

import queryString from 'query-string';

import { useFetchPost } from '@/hooks/queries/posts';
import { Posts } from '@/models/posts';
import { Violations } from '@/models/violations';

interface ViolationContextType {
  postId: number;
  postData?: Posts.Details;
  violationData?: Violations.ViolationDetails | undefined;
  isLoading: boolean;
  isError: boolean;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  resetState: () => void;
}

const INITIAL_VALUES: ViolationContextType = {
  postId: 0,
  postData: undefined,
  violationData: undefined,
  isLoading: false,
  activeIndex: 0,
  isError: false,
  setActiveIndex: () => {
    throw new Error('setActiveIndex not initialized');
  },
  resetState: () => {}
};

export const ViolationContext =
  createContext<ViolationContextType>(INITIAL_VALUES);

interface Props {
  children: JSX.Element;
  postId: number;
  urlParams: queryString.ParsedQuery<string>;
}

export const ViolationProvider = ({ children, postId, urlParams }: Props) => {
  const [activeIndex, setActiveIndex] = useState(INITIAL_VALUES.activeIndex);

  const {
    data: postData,
    isLoading: isPostLoading,
    isError // TODO: Might change with the other endpoint from violations
  } = useFetchPost({ id: postId, params: { ...urlParams } });
  
  const violationData = undefined;
  // const { data: violationData, isLoading: isViolationLoading } =
  //   useViolation(postId);

  // const isLoading = isViolationLoading || isPostLoading;
  const isLoading = isPostLoading;

  const resetState = () => {
    setActiveIndex(INITIAL_VALUES.activeIndex);
  };

  const value = {
    postId,
    postData,
    violationData,
    isLoading,
    isError,
    activeIndex,
    setActiveIndex,
    resetState
  };

  return (
    <ViolationContext.Provider value={value}>
      {children}
    </ViolationContext.Provider>
  );
};
