import { useMutation, useQuery } from '@tanstack/react-query';

import {
  getCreators,
  getCreator,
  getCreatorsAutocomplete,
  getCreatorAvatars,
  getCreatorsPosts,
  createCreator,
  updateCreator,
  deleteCreator,
  getCreatorGroups,
  updateCreatorGroups,
  mergeCreators
} from '@/api/creators';
import { Creator } from '@/models/creator';

type onSuccessType = (data: any) => void;
type onErrorType = (error: any) => void;

type CreatorsProps = {
  params: Creator.ListParams;
  onSuccess?: (_data: Creator.List<Creator.Item>) => void;
  onError?: (_error: unknown) => void;
  enabled?: boolean;
};

export const useFetchCreators = ({
  params,
  onSuccess,
  onError,
  enabled
}: CreatorsProps) =>
  useQuery({
    queryKey: ['creators', params],
    queryFn: async () => {
      const result = await getCreators(params);
      return result.data;
    },
    enabled,
    meta: {
      onSuccess,
      onError
    }
  });

type FetchCreatorProps = {
  id: number;
  onSuccess?: onSuccessType;
  onError?: onErrorType;
};

export const useFetchCreator = ({
  id,
  onSuccess,
  onError
}: FetchCreatorProps) =>
  useQuery({
    queryKey: ['creator', id],
    queryFn: async () => {
      const result = await getCreator(id);
      return result.data;
    },
    meta: {
      onSuccess,
      onError
    }
  });

type FetchCreatorGroupsListProps = {
  id: number;
};

export const useFetchCreatorGroupsList = ({
  id
}: FetchCreatorGroupsListProps) =>
  useQuery({
    queryKey: ['creatorGroupsList', id],
    queryFn: async () => {
      const result = await getCreatorGroups(id);
      return result.data;
    }
  });

type FetchCreatorsAutocompleteProps = {
  params?: Record<string, unknown>;
};

export const useFetchCreatorsAutocomplete = ({
  params
}: FetchCreatorsAutocompleteProps) =>
  useQuery({
    queryKey: ['creatorsAutocomplete', params],
    queryFn: async () => {
      const result = await getCreatorsAutocomplete(params);
      return result.data;
    }
  });

type FetchCreatorAvatarsProps = {
  id: number;
  onSuccess: onSuccessType;
  onError: onErrorType;
};

export const useFetchCreatorAvatars = ({
  id,
  onSuccess,
  onError
}: FetchCreatorAvatarsProps) =>
  useQuery({
    queryKey: ['creatorAvatars', id],
    queryFn: async () => {
      const result = await getCreatorAvatars(id);
      return result.data;
    },
    meta: {
      onSuccess,
      onError
    }
  });

type FetchCreatorPostsProps = {
  id: number;
  params: Record<string, unknown>;
};

export const useFetchCreatorsPosts = ({ id, params }: FetchCreatorPostsProps) =>
  useQuery({
    queryKey: ['creatorsPosts', id, params],
    queryFn: async () => {
      const result = await getCreatorsPosts(id, params);
      return result.data;
    }
  });

type CreateCreatorProps = {
  onSuccess: onSuccessType;
  onError: onErrorType;
};

export const useCreateCreator = ({ onSuccess, onError }: CreateCreatorProps) =>
  useMutation({
    mutationFn: createCreator,
    onSuccess,
    onError
  });

type MergeCreatorsProps = {
  id: number;
  onSuccess: onSuccessType;
  onError: onErrorType;
};

export const useMergeCreators = ({
  id,
  onSuccess,
  onError
}: MergeCreatorsProps) =>
  useMutation({
    mutationFn: (payload: Creator.Merge) => mergeCreators(id, payload),
    onSuccess,
    onError
  });

type UpdateCreatorProps = {
  id: number;
  onSuccess: onSuccessType;
  onError: onErrorType;
};

export const useUpdateCreator = ({
  id,
  onSuccess,
  onError
}: UpdateCreatorProps) =>
  useMutation({
    mutationFn: (payload: Creator.Update) => updateCreator(id, payload),
    onSuccess,
    onError
  });

type UpdateCreatorGroupsProps = {
  id: number;
  onSuccess: onSuccessType;
  onError: onErrorType;
};

export const UpdateCreatorGroups = ({
  id,
  onSuccess,
  onError
}: UpdateCreatorGroupsProps) =>
  useMutation({
    mutationFn: (payload: Creator.UpdateGroupsPayload) =>
      updateCreatorGroups(id, payload),
    onSuccess,
    onError
  });

type DeleteCreatorProps = {
  onSuccess: onSuccessType;
  onError: onErrorType;
};

export const useDeleteCreator = ({ onSuccess, onError }: DeleteCreatorProps) =>
  useMutation({
    mutationFn: deleteCreator,
    onSuccess,
    onError
  });
