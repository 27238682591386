import {
  createContext,
  Dispatch,
  SetStateAction,
  useState,
  useMemo
} from 'react';
import { useSearchParams } from 'react-router-dom';

import queryString from 'query-string';

import { useFetchEvaluation } from '@/hooks/queries/evaluations';
import { useFetchPost } from '@/hooks/queries/posts';
import { Posts } from '@/models/posts';

export interface PostDetailsProviderType {
  postId: number;
  evaluationId: number;
  activeIndex: number;
  setActiveIndex: Dispatch<SetStateAction<number>>;
  data?: Posts.Details;
  videoPlaybackStart?: number;
  setVideoPlaybackStart: Dispatch<SetStateAction<number | undefined>>;
  selectedKeywords: Array<string>;
  setSelectedKeywords: Dispatch<SetStateAction<string[]>>;
  videoLastClick: string | number;
  setVideoLastClick: Dispatch<SetStateAction<string | number>>;
  videoPlayedSeconds: number;
  setVideoPlayedSeconds: Dispatch<SetStateAction<number>>;
  keywordList: Array<string>;
  isLoading: boolean;
  isError: boolean;
  isSuccess: boolean;
  onRefetch: () => void;
  resetState: () => void;
  darkenImageStyle: string;
  minors: Array<Array<Posts.VideoMinorItem | Posts.MinorItem>>;
  hasOcrAdvancedItems: boolean;
  hasOcrOldItems: boolean;
  hasMinorsResults: boolean;
  showIdentificationMarkers: boolean;
  setIdentificationMarkers: Dispatch<SetStateAction<boolean>>;
  isRecognitionHighlighted0to6: boolean;
  setRecognitionHighlighted0to6: Dispatch<SetStateAction<boolean>>;
  isRecognitionHighlighted7to14: boolean;
  setRecognitionHighlighted7to14: Dispatch<SetStateAction<boolean>>;
  isRecognitionHighlighted15to17: boolean;
  setRecognitionHighlighted15to17: Dispatch<SetStateAction<boolean>>;
  hasVRForMinorsSelected: boolean;
  selectedMinorItems: Posts.MinorItem[];
  setSelectedMinorItems: Dispatch<SetStateAction<Posts.MinorItem[]>>;
  selectedOcrAdvItems: Array<Posts.OcrAdvImageItem>;
  setSelectedOcrAdvItems: Dispatch<SetStateAction<Posts.OcrAdvImageItem[]>>;
  markerItems: (Posts.MinorItem | Posts.OcrAdvImageItem)[];
  resetMarkers: () => void;
}

const INITIAL_VALUES: PostDetailsProviderType = {
  postId: 0,
  evaluationId: 0,
  activeIndex: 0,
  setActiveIndex: () => {
    throw new Error('setActiveIndex not initialized');
  },
  data: undefined,
  videoPlaybackStart: undefined,
  setVideoPlaybackStart: () => {
    throw new Error('setVideoPlaybackStart not initialized');
  },
  selectedKeywords: [],
  setSelectedKeywords: () => {
    throw new Error('setSelectedKeywords not initialized');
  },
  videoLastClick: new Date().toTimeString(),
  setVideoLastClick: () => {
    throw new Error('setVideoLastClick not initialized');
  },
  videoPlayedSeconds: 0,
  setVideoPlayedSeconds: () => {
    throw new Error('setSelectedKeywords not initialized');
  },
  keywordList: [],
  isLoading: false,
  isError: false,
  isSuccess: false,
  onRefetch: () => {},
  resetState: () => {},
  darkenImageStyle: '',
  minors: [],
  hasOcrOldItems: false,
  hasOcrAdvancedItems: false,
  hasMinorsResults: false,
  showIdentificationMarkers: true,
  setIdentificationMarkers: () => {
    throw new Error('setIdentificationMarkers not initialized');
  },
  isRecognitionHighlighted0to6: false,
  setRecognitionHighlighted0to6: () => {
    throw new Error('setRecognitionHighlighted0to6 not initialized');
  },
  isRecognitionHighlighted7to14: false,
  setRecognitionHighlighted7to14: () => {
    throw new Error('setRecognitionHighlighted7to14 not initialized');
  },
  isRecognitionHighlighted15to17: false,
  setRecognitionHighlighted15to17: () => {
    throw new Error('setRecognitionHighlighted15to17 not initialized');
  },
  hasVRForMinorsSelected: false,
  selectedMinorItems: [],
  setSelectedMinorItems: () => {
    throw new Error('setSelectedMinorItems not initialized');
  },
  selectedOcrAdvItems: [],
  setSelectedOcrAdvItems: () => {
    throw new Error('setSelectedOcrAdvItems not initialized');
  },
  markerItems: [],
  resetMarkers: () => {}
};

export const PostDetailsContext =
  createContext<PostDetailsProviderType>(INITIAL_VALUES);

interface Props {
  children: JSX.Element;
  evaluationId: number;
  postId: number;
  urlParams: queryString.ParsedQuery<string>;
}

export const PostDetailsProvider = ({
  children,
  evaluationId,
  postId,
  urlParams
}: Props) => {
  const [searchParams] = useSearchParams();

  const { data, isLoading, isError, isSuccess, refetch } = useFetchPost({
    id: postId,
    params: {
      evaluationId,
      ...urlParams,
      order: searchParams.get('order') || 'desc',
      sort: searchParams.get('sort') || 'createTime'
    },
    staleTime: 0
  });

  const { data: evaluationData } = useFetchEvaluation({
    id: evaluationId
  });

  const hasVRForMinorsSelected = evaluationData?.requestedServices?.some(
    (service: any) => service.type === 'minors'
  );

  const [videoPlaybackStart, setVideoPlaybackStart] = useState(
    INITIAL_VALUES.videoPlaybackStart
  );

  const [activeIndex, setActiveIndex] = useState(INITIAL_VALUES.activeIndex);

  const [selectedKeywords, setSelectedKeywords] = useState(
    INITIAL_VALUES.selectedKeywords
  );

  const [videoLastClick, setVideoLastClick] = useState(
    INITIAL_VALUES.videoLastClick
  );

  const [videoPlayedSeconds, setVideoPlayedSeconds] = useState(
    INITIAL_VALUES.videoPlayedSeconds
  );

  const [showIdentificationMarkers, setIdentificationMarkers] = useState(
    INITIAL_VALUES.showIdentificationMarkers
  );

  const [isRecognitionHighlighted0to6, setRecognitionHighlighted0to6] =
    useState(INITIAL_VALUES.isRecognitionHighlighted0to6);

  const [isRecognitionHighlighted7to14, setRecognitionHighlighted7to14] =
    useState(INITIAL_VALUES.isRecognitionHighlighted7to14);

  const [isRecognitionHighlighted15to17, setRecognitionHighlighted15to17] =
    useState(INITIAL_VALUES.isRecognitionHighlighted15to17);

  const [selectedMinorItems, setSelectedMinorItems] = useState(
    INITIAL_VALUES.selectedMinorItems
  );

  const [selectedOcrAdvItems, setSelectedOcrAdvItems] = useState(
    INITIAL_VALUES.selectedOcrAdvItems
  );

  const resetState = () => {
    setVideoPlaybackStart(INITIAL_VALUES.videoPlaybackStart);
    setActiveIndex(INITIAL_VALUES.activeIndex);
    setSelectedKeywords(INITIAL_VALUES.selectedKeywords);
    setVideoLastClick(INITIAL_VALUES.videoLastClick);
    setIdentificationMarkers(INITIAL_VALUES.showIdentificationMarkers);
    setSelectedMinorItems(INITIAL_VALUES.selectedMinorItems);
    setSelectedOcrAdvItems(INITIAL_VALUES.selectedOcrAdvItems);
    setRecognitionHighlighted0to6(INITIAL_VALUES.isRecognitionHighlighted0to6);
    setRecognitionHighlighted7to14(
      INITIAL_VALUES.isRecognitionHighlighted7to14
    );
    setRecognitionHighlighted15to17(
      INITIAL_VALUES.isRecognitionHighlighted15to17
    );
  };

  const keywordList = useMemo(() => {
    const keywordsListsMerged = data?.evaluation.keywords.concat(
      data.evaluation.keywordGroups.flatMap((item) => item.keywords)
    );
    const keywordListWithoutDuplicates = [...new Set(keywordsListsMerged)];
    return keywordListWithoutDuplicates;
  }, [data]);

  const minors = useMemo(
    () =>
      (data?.media || []).map((media) => {
        const vrOnlyMinors =
          media?.visualRecognition
            ?.find((item) => item.type === 'minors')
            ?.items.filter(
              (item): item is Posts.MinorItem | Posts.VideoMinorItem =>
                'minorsFound' in item || 'coordinates' in item
            ) || [];

        return vrOnlyMinors;
      }),
    [data?.media]
  );

  type OcrAdvItem = Posts.OcrAdvImageItem | Posts.OcrAdvVideoItem;

  const hasOcrAdvancedItems = useMemo(() => {
    const activeMedia = data?.media?.[activeIndex];
    if (!activeMedia) return false;

    const vrOnlyOcrAdv = activeMedia.visualRecognition
      ?.find((item) => item.type === 'ocr-adv')
      ?.items.some((item): item is OcrAdvItem => {
        const hasTextFound = 'textFound' in item;
        const hasContent = 'content' in item;
        return hasTextFound || hasContent;
      });

    return !!vrOnlyOcrAdv;
  }, [data?.media, activeIndex]);

  const hasOcrOldItems = useMemo(() => {
    const activeMedia = data?.media?.[activeIndex];
    if (!activeMedia) return false;

    const vrOnlyOcr = activeMedia.visualRecognition?.some(
      (item): item is Posts.Recognition & { type: 'ocr'; text: string } =>
        item.type === 'ocr'
    );

    return !!vrOnlyOcr;
  }, [data?.media, activeIndex]);

  const hasMinorsResults = useMemo(
    () =>
      data?.media[activeIndex]?.visualRecognition?.some(
        (item) => item.type === 'minors' && item.items.length > 0
      ) || false,
    [data?.media[activeIndex]]
  );

  const markerItems = useMemo(
    () => [...selectedMinorItems, ...selectedOcrAdvItems],
    [selectedMinorItems, selectedOcrAdvItems]
  );

  // This variable can be needed if we want to combine different types of visual recognition results
  // const hasVisualRecognitionResults = hasMinorsResults;

  const darkenImageStyle = useMemo(
    () =>
      showIdentificationMarkers &&
      (selectedMinorItems.length > 0 || selectedOcrAdvItems.length > 0)
        ? 'brightness(75%) contrast(75%)'
        : '',
    [showIdentificationMarkers, selectedMinorItems, selectedOcrAdvItems]
  );

  const onRefetch = () => {
    refetch();
  };

  const resetMarkers = () => {
    setSelectedMinorItems([]);
    setSelectedOcrAdvItems([]);
  };

  const value = {
    evaluationId,
    onRefetch,
    postId,
    data,
    isLoading,
    isError,
    isSuccess,
    videoPlaybackStart,
    setVideoPlaybackStart,
    activeIndex,
    setActiveIndex,
    selectedKeywords,
    setSelectedKeywords,
    videoLastClick,
    setVideoLastClick,
    videoPlayedSeconds,
    setVideoPlayedSeconds,
    keywordList,
    resetState,
    darkenImageStyle,
    minors,
    hasOcrAdvancedItems,
    hasOcrOldItems,
    hasMinorsResults,
    showIdentificationMarkers,
    setIdentificationMarkers,
    isRecognitionHighlighted0to6,
    setRecognitionHighlighted0to6,
    isRecognitionHighlighted7to14,
    setRecognitionHighlighted7to14,
    isRecognitionHighlighted15to17,
    setRecognitionHighlighted15to17,
    hasVRForMinorsSelected,
    selectedMinorItems,
    setSelectedMinorItems,
    selectedOcrAdvItems,
    setSelectedOcrAdvItems,
    markerItems,
    resetMarkers
  };

  return (
    <PostDetailsContext.Provider value={value}>
      {children}
    </PostDetailsContext.Provider>
  );
};
