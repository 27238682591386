import { useCallback, useMemo, useRef } from 'react';

import {
  Cancel,
  CancelScheduleSend,
  DoneOutline,
  ErrorOutline,
  ExpandLess,
  ExpandMore,
  QuestionAnswer,
  Search,
  Send
} from '@mui/icons-material';
import { Chip, Stack, Tooltip, Typography } from '@mui/material';
import {
  getGridSingleSelectOperators,
  GridEventListener,
  GridRenderCellParams,
  GridToolbarColumnsButton,
  GridToolbarContainer,
  GridToolbarDensitySelector,
  GridToolbarExport,
  GridToolbarFilterButton,
  GridValueFormatterParams
} from '@mui/x-data-grid';
import { DataGridProProps, useGridApiRef } from '@mui/x-data-grid-pro';

import Page from '@/components/Page';
import Avatar from '@/components/avatar';
import Button from '@/components/button';
import DataTable from '@/components/dataTable';
import NoRowsOverlay from '@/components/dataTable/customNoRowsOverlay/NoRowsOverlay';
import OnBoardingOverlay from '@/components/dataTable/customNoRowsOverlay/OnBoardingOverlay';
import dateRangeOperator from '@/components/dataTable/customOperators/dateRangeOperator';
import Header from '@/components/header';
import Toolbar from '@/components/toolbar';
import PLATFORM_MAPPER from '@/constants/platformMapper';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants/table';
import { useViolations } from '@/hooks/queries/violations';
import useTableParams from '@/hooks/useTableParams';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { Platform } from '@/models/posts';
import { Violations } from '@/models/violations';
import { formatDate } from '@/utils/datesAndTime';
import getRoute from '@/utils/getRoute';
import PreviewColumn from '@/views/evaluations/detail/body/_partials/PreviewColumn';
import Error from '@/views/misc/error';

import DetailPanel from './_partials/DetailPanel';

const getInitialFilterModelItems = (params: Record<string, any>) => {
  const { creator, status, contacted, uploadDate, secondOpinion, platform } =
    params;

  const items = [];

  if (status !== undefined) {
    items.push({
      field: 'status',
      id: items.length,
      operator: 'isAnyOf',
      value: status.split(',')
    });
  }
  if (contacted !== undefined)
    items.push({
      field: 'contacted',
      id: items.length,
      operator: 'is',
      value: contacted
    });
  if (creator !== undefined)
    items.push({
      field: 'creator',
      id: items.length,
      operator: 'isAnyOf',
      value: creator.split(',')
    });
  if (uploadDate !== undefined)
    items.push({
      field: 'uploadDate',
      id: items.length,
      operator: 'between',
      value: uploadDate.split(',').map((item: string) => new Date(item))
    });
  if (secondOpinion !== undefined)
    items.push({
      field: 'secondOpinion',
      id: items.length,
      operator: 'is',
      value: secondOpinion
    });
  if (platform !== undefined)
    items.push({
      field: 'platform',
      id: items.length,
      operator: 'isAnyOf',
      value: platform.split(',')
    });
  return items;
};

const ViolationsOnBoardingOverlay = () => (
  <OnBoardingOverlay
    icon={<ErrorOutline color="action" sx={{ width: 40, height: 40 }} />}
    title="Start reviewing Violations"
    text="Create an evaluation to start analysing social media posts for violations"
  />
);

const defaultParams = {
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE,
  sort: undefined,
  order: undefined,
  name: undefined,
  status: undefined,
  contacted: undefined,
  uploadDate: undefined,
  secondOpinion: undefined,
  platform: undefined,
  creator: undefined
};

const ViolationsList = () => {
  const apiRef = useGridApiRef();
  const navigate = useWorkspaceNavigate();
  const { params, handlers, updateParams, paramsInTheUrl } = useTableParams(
    getRoute.violations.LIST(),
    defaultParams
  );

  const {
    data: violations,
    isLoading,
    isError
  } = useViolations({
    params: {
      ...params
    }
  });

  const { data: violationCreators } = useViolations({});

  const creatorOptions = useMemo(() => {
    if (!violationCreators?.results) return [];
    return Array.from(
      new Set(violationCreators.results.map((v) => v.creator.id))
    ).map((id) => {
      const creator = violationCreators.results.find(
        (v) => v.creator.id === id
      )?.creator;
      return { value: id, label: creator?.name || `ID: ${id}` };
    });
  }, [violationCreators]);

  const initialFilterModel = useRef({
    items: getInitialFilterModelItems(params)
  });

  const onRowClick = useCallback<GridEventListener<'rowClick'>>(
    (params) => {
      apiRef.current.toggleDetailPanel(params.id);
    },
    [apiRef]
  );
  const handleReviewClick = useCallback(
    (postId: number): void => {
      navigate(getRoute.violations.DETAIL(postId));
    },
    [navigate]
  );

  const getDetailPanelContent = useCallback<
    NonNullable<DataGridProProps['getDetailPanelContent']>
  >(
    ({ row }) => <DetailPanel row={row} onReviewClick={handleReviewClick} />,
    [handleReviewClick]
  );

  const getDetailPanelHeight = useCallback<
    NonNullable<DataGridProProps['getDetailPanelHeight']>
  >(() => 'auto' as const, []);

  const columns = useMemo(
    () => [
      {
        filterable: false,
        sortable: false,
        field: 'previewUrl',
        headerName: 'Preview',
        minWidth: 130,
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<Violations.ViolationRecord>
        ) => <PreviewColumn previewUrl={params.row.previewUrl} />
      },
      {
        sortable: false,
        field: 'creator',
        headerName: 'Creator',
        minWidth: 200,
        flex: 1,
        type: 'singleSelect',
        filterOperators: getGridSingleSelectOperators().filter(
          (operator) => operator.value === 'isAnyOf'
        ),
        valueFormatter: (
          params: GridValueFormatterParams<Violations.Creator>
        ) => params.value.name || '—',
        valueOptions: creatorOptions,
        renderCell: (
          data: GridRenderCellParams<Violations.ViolationRecord>
        ) => {
          const { creator } = data.row;

          return (
            <Stack
              direction="row"
              alignItems="center"
              overflow="hidden"
              gap={1}
            >
              <Avatar
                src={creator.avatarUrl || ''}
                name={creator.name}
                width="40px"
                fontSize={16}
              />
              <Typography
                noWrap
                sx={{
                  overflow: 'hidden',
                  textOverflow: 'ellipsis',
                  whiteSpace: 'nowrap',
                  flexShrink: 1,
                  minWidth: 0
                }}
              >
                {creator.name}
              </Typography>
            </Stack>
          );
        }
      },
      {
        field: 'uploadDate',
        headerName: 'Posting Time',
        minWidth: 160,
        type: 'date',
        filterOperators: dateRangeOperator,
        valueFormatter: (
          params: GridValueFormatterParams<Violations.ViolationRecord>
        ) => String(params.value),
        flex: 1,
        renderCell: (
          data: GridRenderCellParams<Violations.ViolationRecord>
        ) => {
          const { uploadDate } = data.row;

          return (
            <Typography>
              {formatDate(new Date(uploadDate), 'dd/MM/yyyy HH:mm')}
            </Typography>
          );
        }
      },
      {
        sortable: false,
        field: 'platform',
        headerName: 'Platform',
        type: 'singleSelect',
        valueOptions: Object.keys(PLATFORM_MAPPER).map((key) => ({
          value: key,
          label: PLATFORM_MAPPER[key as Platform]
        })),
        minWidth: 150,
        filterOperators: getGridSingleSelectOperators().filter(
          (operator) => operator.value === 'isAnyOf'
        ),
        flex: 1,
        renderCell: (
          params: GridRenderCellParams<Violations.ViolationRecord>
        ) => <Chip label={PLATFORM_MAPPER[params.row.platform as Platform]} />
      },
      {
        sortable: false,
        field: 'secondOpinion',
        headerName: 'Second Opinion',
        minWidth: 120,
        type: 'boolean',
        valueFormatter: (
          params: GridValueFormatterParams<Violations.SecondOpinion>
        ) => params.value.opinion || '—',
        flex: 1,
        renderCell: (
          data: GridRenderCellParams<Violations.ViolationRecord>
        ) => {
          const { secondOpinion } = data.row;

          if (!secondOpinion || Object.keys(secondOpinion).length === 0) {
            return <>—</>;
          }

          return secondOpinion.opinion ? (
            <Typography fontWeight={700} color="primary" variant="body2">
              Agree
            </Typography>
          ) : (
            <Typography fontWeight={700} color="error" variant="body2">
              Disagree
            </Typography>
          );
        }
      },
      {
        sortable: false,
        type: 'boolean',
        field: 'contacted',
        headerName: 'Contacted',
        valueFormatter: (
          params: GridValueFormatterParams<Violations.Contacted>
        ) => params.value.contact || '—',
        minWidth: 130,
        flex: 1,
        renderCell: (
          data: GridRenderCellParams<Violations.ViolationRecord>
        ) => {
          const { contacted } = data.row;

          if (contacted.contactedAt && contacted.author) {
            return (
              <Tooltip
                arrow
                title={
                  <Stack>
                    <Typography
                      variant="caption"
                      textAlign="center"
                      fontWeight="bold"
                    >
                      Time contacted:
                    </Typography>
                    <Typography variant="caption" textAlign="center">
                      {formatDate(
                        new Date(contacted.contactedAt),
                        'dd/MM/yyyy HH:MM'
                      )}
                    </Typography>
                    <Typography
                      variant="caption"
                      textAlign="center"
                      fontWeight="bold"
                    >{`@${contacted.author}`}</Typography>
                  </Stack>
                }
                placement="top"
              >
                <Chip
                  color="success"
                  icon={<Send fontSize="inherit" />}
                  label="Contacted"
                />
              </Tooltip>
            );
          }

          return (
            <Chip
              color="error"
              icon={<CancelScheduleSend fontSize="inherit" />}
              label="No contact"
            />
          );
        }
      },
      {
        field: 'status',
        headerName: 'Status',
        type: 'singleSelect',
        valueOptions: [
          { value: 'open', label: 'Open' },
          { value: 'closed', label: 'Closed' },
          { value: 'other', label: 'Other' }
        ],
        valueFormatter: (params: GridValueFormatterParams<Violations.Status>) =>
          params.value.stage || '—',
        filterOperators: getGridSingleSelectOperators().filter(
          (operator) => operator.value === 'isAnyOf'
        ),
        minWidth: 130,
        flex: 1,
        renderCell: (
          data: GridRenderCellParams<Violations.ViolationRecord>
        ) => {
          const { status } = data.row;

          switch (status.stage) {
            case 'open':
              return (
                <Chip
                  icon={<Search fontSize="small" />}
                  label="Open"
                  color="warning"
                />
              );

            case 'closed':
              return (
                <Chip
                  icon={<DoneOutline fontSize="small" />}
                  label="Closed"
                  color="success"
                />
              );

            case 'other':
              return (
                <Chip
                  icon={<QuestionAnswer fontSize="small" />}
                  label="Other"
                  color="default"
                />
              );

            default:
              return (
                <Chip
                  icon={<Cancel fontSize="small" />}
                  label="Unknown"
                  color="default"
                />
              );
          }
        }
      }
    ],
    [violations, violationCreators]
  );

  const rows = useMemo(
    () =>
      violations?.results.map((item: Violations.ViolationRecord) => ({
        ...item,
        id: item.postId
      })),
    [violations]
  );

  const onFilterModelChange = useCallback(
    (params: Record<string, any>) => {
      const newParams: Record<string, any> = {};

      // Iterate over active filters and format newParams based on filter fields and values
      params.items.forEach((item: { field: string; value: any }) => {
        const { field, value } = item;

        if (field === 'uploadDate' && value !== undefined) {
          // Handle date ranges by formatting them into 'yyyy-MM-dd' strings
          const formattedDates: string[] = value.map((date: Date) =>
            formatDate(date, 'yyyy-MM-dd')
          );
          const isSomeDateDefined: boolean = formattedDates.some(
            (date: string) => date !== undefined
          );

          newParams.uploadDate = isSomeDateDefined
            ? formattedDates.join(',')
            : undefined;
        } else if (field === 'status' && value !== undefined) {
          // Handle 'status' as it's a singleSelect field
          newParams.status = value as string;
        } else if (Array.isArray(value)) {
          // Handle array values (like creators or multi-select fields)
          newParams[field] = (value as string[]).join(',');
        } else {
          newParams[field] = value as string | undefined;
        }
      });

      // Remove any filters no longer active (reset them to undefined)
      columns
        .filter((column) => column.field)
        .forEach((column) => {
          const filterExistsInTheUrl = Object.keys(paramsInTheUrl).includes(
            column.field
          );
          const isFilterActive = params.items.some(
            (item: { field: string }) => item.field === column.field
          );

          if (filterExistsInTheUrl && !isFilterActive) {
            newParams[column.field] = undefined;
          }
        });

      // Reset to the first page and update URL params with active filters
      updateParams({
        ...paramsInTheUrl,
        ...newParams,
        page: DEFAULT_PAGE
      });
    },
    [columns, paramsInTheUrl, updateParams]
  );

  let emptyStateViolations = ViolationsOnBoardingOverlay;
  if (params.name !== undefined) emptyStateViolations = () => <NoRowsOverlay />;

  if (isError) return <Error message="Failed to load violations" />;

  return (
    <Page
      title="Violations"
      header={<Header title="Violations" />}
      toolbar={
        <Toolbar
          isInputDisabled
          onChangeSearch={handlers.onChangeSearch}
          showSearchInput
          searchValue={params.name ? String(params.name) : ''}
          totalItems={violations?.totalItems ?? 0}
        />
      }
    >
      <DataTable
        apiRef={apiRef}
        columns={columns}
        rowHeight={72}
        rows={rows || []}
        loading={isLoading}
        filterMode="server"
        paginationMode="server"
        sortingMode="server"
        onChangePageSize={handlers.onChangePageSize}
        onChangePage={handlers.onChangePage}
        onSortModelChange={handlers.onSortModelChange}
        onFilterModelChange={onFilterModelChange}
        initialFilterModel={initialFilterModel.current}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        slots={{
          detailPanelExpandIcon: ExpandMore,
          detailPanelCollapseIcon: ExpandLess,
          toolbar: () => (
            <GridToolbarContainer
              sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                padding: '8px 12px 0 12px',
                height: '48px'
              }}
            >
              <Stack direction="row" alignItems="center" gap={1} flexGrow={1}>
                <GridToolbarColumnsButton />
                <GridToolbarFilterButton />
                <GridToolbarDensitySelector />
                <GridToolbarExport />
              </Stack>
              <Stack
                direction="row"
                alignItems="center"
                justifyContent="flex-end"
                gap={1}
              >
                <Button
                  text={
                    paramsInTheUrl.status === 'open,other'
                      ? 'Show All Violations'
                      : 'Hide Closed Violations'
                  }
                  variant="text"
                  color="primary"
                  onClick={() => {
                    const newStatus =
                      paramsInTheUrl.status === 'open,other'
                        ? undefined
                        : 'open,other';
                    updateParams({
                      ...paramsInTheUrl,
                      status: newStatus,
                      page: DEFAULT_PAGE
                    });
                  }}
                  size="small"
                />
              </Stack>
            </GridToolbarContainer>
          )
        }}
        onRowClick={onRowClick}
        rowCount={violations?.totalItems}
        sort={params.sort}
        order={params.order}
        page={params.page}
        size={params.size}
        noRowsOverlay={emptyStateViolations}
      />
    </Page>
  );
};

export default ViolationsList;
