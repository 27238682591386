import { useMemo, useState, useEffect } from 'react';
import { useParams } from 'react-router';

import { ArrowBack, ArrowForward } from '@mui/icons-material';
import { Container, Skeleton, Stack, Tooltip } from '@mui/material';

import Button from '@/components/button';
import Header from '@/components/header';
import Post from '@/components/post';
import { useFetchCreatorsPosts } from '@/hooks/queries/creators';
import { useFetchPost } from '@/hooks/queries/posts';
import useArrowKeyNavigation from '@/hooks/useArrowKeyNavigation';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import getRoute from '@/utils/getRoute';
import Error from '@/views/misc/error';
import NotFound from '@/views/misc/notFound';

const CreatorPostSkeleton = () => (
  <Stack gap={2} sx={{ height: 'calc(100vh - 108px)' }}>
    <Skeleton variant="rectangular" height={75.25} />
    <Skeleton variant="rectangular" height="calc(100% - 72px)" />
  </Stack>
);
const CreatorPost = () => {
  const { postId, id: creatorId } = useParams();
  const navigate = useWorkspaceNavigate();
  const [isNavigating, setIsNavigating] = useState(false);

  // Convert to numbers and handle potential NaN values
  const numericPostId = Number(postId);
  const numericCreatorId = Number(creatorId);

  // Fetch current post
  const {
    data: postData,
    isPending: isPostPending,
    isError: isPostError
  } = useFetchPost({ id: numericPostId });

  // State to track navigation within the paginated data
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const PAGE_SIZE = 50;

  // Fetch creator posts with pagination
  const {
    data: creatorPosts,
    isPending: isCreatorPostsPending,
    refetch: refetchCreatorPosts
  } = useFetchCreatorsPosts({
    id: numericCreatorId,
    params: {
      page: currentPage,
      size: PAGE_SIZE
    }
  });

  // Calculate total pages when data is loaded
  useEffect(() => {
    if (creatorPosts?.totalItems) {
      setTotalPages(Math.ceil(creatorPosts.totalItems / PAGE_SIZE));
    }
  }, [creatorPosts?.totalItems]);

  // Find the correct page for the current post if we don't have it yet
  useEffect(() => {
    if (
      !isNavigating &&
      !isCreatorPostsPending &&
      creatorPosts?.results &&
      !creatorPosts.results.some((post) => post.id === numericPostId)
    ) {
      // If the current post is not in our current page, we need to find it
      const estimatedPage = Math.ceil(numericPostId / PAGE_SIZE);
      setCurrentPage((prevPage) => {
        // Try a different page strategy if we have more information
        if (creatorPosts.totalItems && creatorPosts.results.length > 0) {
          // Use the highest and lowest IDs to estimate the correct page
          const highestId = creatorPosts.results[0].id;
          const lowestId =
            creatorPosts.results[creatorPosts.results.length - 1].id;
          const postsPerPage = highestId - lowestId + 1;

          if (postsPerPage > 0) {
            return Math.max(
              1,
              Math.min(
                totalPages,
                Math.ceil((highestId - numericPostId) / postsPerPage) +
                  currentPage
              )
            );
          }
        }
        return estimatedPage > 0 ? estimatedPage : prevPage + 1;
      });

      refetchCreatorPosts();
    }
  }, [
    numericPostId,
    creatorPosts,
    isCreatorPostsPending,
    currentPage,
    totalPages,
    refetchCreatorPosts,
    isNavigating
  ]);

  const orderedPostIds = useMemo(
    () => creatorPosts?.results?.map((post) => post.id) || [],
    [creatorPosts]
  );

  const currentIndex = orderedPostIds.indexOf(numericPostId);
  const hasPreviousPost = currentIndex > 0;
  const hasNextPost =
    currentIndex >= 0 && currentIndex < orderedPostIds.length - 1;

  // Handle navigation to previous page if we're at the start of current page
  const handlePreviousPost = () => {
    setIsNavigating(true);
    if (currentIndex > 0) {
      // Simple case: previous post is in the current page
      navigate(
        getRoute.creators.POST(
          numericCreatorId,
          orderedPostIds[currentIndex - 1]
        )
      );
    } else if (currentPage > 1) {
      // Need to go to previous page and select the last post
      setCurrentPage((prev) => prev - 1);
      refetchCreatorPosts().then((response) => {
        const previousPagePosts = response.data?.results || [];
        if (previousPagePosts.length > 0) {
          const lastPostId = previousPagePosts[previousPagePosts.length - 1].id;
          navigate(getRoute.creators.POST(numericCreatorId, lastPostId));
        }
      });
    }
    setIsNavigating(false);
  };

  // Handle navigation to next page if we're at the end of current page
  const handleNextPost = () => {
    setIsNavigating(true);
    if (currentIndex < orderedPostIds.length - 1) {
      // Simple case: next post is in the current page
      navigate(
        getRoute.creators.POST(
          numericCreatorId,
          orderedPostIds[currentIndex + 1]
        )
      );
    } else if (currentPage < totalPages) {
      // Need to go to next page and select the first post
      setCurrentPage((prev) => prev + 1);
      refetchCreatorPosts().then((response) => {
        const nextPagePosts = response.data?.results || [];
        if (nextPagePosts.length > 0) {
          const firstPostId = nextPagePosts[0].id;
          navigate(getRoute.creators.POST(numericCreatorId, firstPostId));
        }
      });
    }
    setIsNavigating(false);
  };

  const handleReturnToCreator = () => {
    navigate(getRoute.creators.DETAIL(numericCreatorId));
  };

  useArrowKeyNavigation({
    onLeft: () => {
      if (hasPreviousPost || currentPage > 1) {
        handlePreviousPost();
      }
    },
    onRight: () => {
      if (hasNextPost || currentPage < totalPages) {
        handleNextPost();
      }
    }
  });

  // Handle validation and error cases
  if (!postId || Number.isNaN(numericPostId)) return <NotFound />;
  if (isPostError) return <Error message="Failed to load post" />;

  const isPending = isPostPending || isCreatorPostsPending;
  const canNavigatePrevious = hasPreviousPost || currentPage > 1;
  const canNavigateNext = hasNextPost || currentPage < totalPages;

  return (
    <Container maxWidth={false}>
      <Header
        sx={{ pt: 3 }}
        mb
        isSmallTitle
        actions={
          <>
            <Tooltip title="Left arrow key for previous post" arrow>
              <span>
                <Button
                  disabled={!canNavigatePrevious || isPending}
                  onClick={handlePreviousPost}
                  size="small"
                  startIcon={<ArrowBack />}
                  text="Previous"
                />
              </span>
            </Tooltip>
            <Tooltip title="Right arrow key for next post" arrow>
              <span>
                <Button
                  disabled={!canNavigateNext || isPending}
                  onClick={handleNextPost}
                  endIcon={<ArrowForward />}
                  size="small"
                  text="Next"
                />
              </span>
            </Tooltip>
          </>
        }
        onClickReturn={handleReturnToCreator}
        showDivider
        title={
          postData?.creator?.name || <Skeleton variant="text" width="200px" />
        }
      />
      {!isPostPending ? <Post postData={postData} /> : <CreatorPostSkeleton />}
    </Container>
  );
};

export default CreatorPost;
