import { Platform } from '@/models/posts';

const PLATFORM_MAPPER: Record<Platform, string> = {
  'instagram-post': 'IG: Post',
  'instagram-reel': 'IG: Reel',
  'instagram-story': 'IG: Story',
  'tiktok-post': 'TT: Video',
  'youtube-short': 'YT: Short',
  'youtube-video': 'YT: Video',
  'youtube-livestream': 'YT: Livestream'
};

export default PLATFORM_MAPPER;
