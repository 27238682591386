import { Typography } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import { useQueryClient } from '@tanstack/react-query';

import Button from '@/components/button';
import Dialog from '@/components/dialog';
import { useDeleteKeywordGroups } from '@/hooks/queries/keywords';
import useAlert from '@/hooks/useAlert';

interface DeleteKeywordGroupsProps {
  isOpen: boolean;
  onClose: () => void;
  selected: GridRowSelectionModel;
  clearSelection: () => void;
  groupsList: { id: number; title: string }[];
}

const DeleteKeywordGroups = ({
  isOpen,
  onClose,
  selected,
  clearSelection,
  groupsList
}: DeleteKeywordGroupsProps) => {
  const alert = useAlert();
  const queryClient = useQueryClient();

  const { mutate: deleteKeywordGroups } = useDeleteKeywordGroups(
    () => {
      const groupsToDelete = groupsList
        .filter((group) => selected.includes(group.id))
        .map((group) => group.title);

      const alertMessage =
        selected.length === 1
          ? `"${groupsToDelete[0]}" has been successfully deleted`
          : `${selected.length} groups have been successfully deleted`;

      alert.info(alertMessage);
      queryClient.invalidateQueries({
        queryKey: ['keywordGroups'],
        refetchType: 'all'
      });
      clearSelection();
      onClose();
    },
    () => {
      alert.error(`Some error occurred while deleting the groups`);
      onClose();
    }
  );

  const handleConfirmDeleteGroup = () => {
    deleteKeywordGroups({
      categoryIds: selected.join(',')
    });
  };

  return (
    <Dialog
      title="Delete Keyword Group"
      dialogContent={
        selected.length === 1 ? (
          <>
            <Typography>
              Are you sure you want to delete &ldquo;
              {groupsList.find((item) => item.id === selected[0])?.title}
              &ldquo;?
            </Typography>
            <Typography>
              You will not be able to recover this keyword group.
            </Typography>
          </>
        ) : (
          <>
            <Typography>
              Are you sure you want to delete {selected.length} keyword groups?
            </Typography>
            <Typography>You will not be able to recover these.</Typography>
          </>
        )
      }
      open={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} variant="text" text="cancel" />
          <Button
            onClick={handleConfirmDeleteGroup}
            color="error"
            text="delete"
          />
        </>
      }
    />
  );
};

export default DeleteKeywordGroups;
