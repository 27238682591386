import { useMutation, useQuery } from '@tanstack/react-query';

import { getUserSelf, updateUserSelf } from '@/api/users';

type onSuccessType = (data: any) => void;
type onErrorType = (error: any) => void;

export const useFetchEvaluation = (
  onSuccess: onSuccessType,
  onError: onErrorType
) =>
  useQuery({
    queryKey: ['user'],
    queryFn: async () => {
      const result = await getUserSelf();
      return result.data;
    },
    meta: {
      onSuccess,
      onError
    }
  });

export const useUpdateUser = (
  onSuccess?: onSuccessType,
  onError?: onErrorType
) =>
  useMutation({
    mutationFn: updateUserSelf,
    onSuccess,
    onError
  });
