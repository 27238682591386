import { useContext } from 'react';

import { Grid, Skeleton, Stack, styled } from '@mui/material';

import Post from '@/components/post/Post';
import { PostDetailsContext } from '@/contexts/PostDetailsContext';
import shadows from '@/themes/theme/shadows';
import Error from '@/views/misc/error';

import PostCompliance from './_partials/PostCompliance';
import PostKeywordsDetails from './_partials/PostKeywordsDetails';
import PostTranscript from './_partials/PostTranscript';
import PostVisuals from './_partials/PostVisuals';

const ScrollableStack = styled(Stack)(({ theme }) => ({
  display: 'block',
  maxHeight: '100%',
  overflowY: 'auto',
  overflowX: 'hidden',
  padding: '16px',
  boxSizing: 'border-box',
  backgroundColor: theme.palette.background.paper,
  boxShadow: shadows.cardDefault,
  border: `1px solid ${theme.palette.divider}`,
  position: 'relative',

  // More prominent scrollbar
  scrollbarWidth: 'thin',
  scrollbarColor: `${theme.palette.primary.main} ${theme.palette.background.paper}`,

  // Enhanced spacing and borders between components
  '& > *': {
    border: `1px solid ${theme.palette.divider}`,
    transition: 'transform 0.2s ease-in-out, box-shadow 0.2s ease-in-out'
  }
}));

const ViolationSkeleton = () => (
  <Grid
    container
    spacing={2}
    sx={{
      height: 'calc(100vh - 108px)' // Full viewport height minus any header offset
    }}
  >
    {/* Left Grid */}
    <Grid item xs={12} md={8} sx={{ height: '100%' }}>
      <Stack gap={2} sx={{ height: '100%' }}>
        <Skeleton variant="rectangular" height={75.25} />
        <Skeleton variant="rectangular" height="calc(100% - 72px)" />
      </Stack>
    </Grid>

    {/* Right Grid */}
    <Grid item xs={12} md={4} sx={{ height: '100%' }}>
      <Skeleton variant="rectangular" height="100%" />
    </Grid>
  </Grid>
);

const PostLayout = () => {
  const {
    data: postData,
    isError,
    isLoading,
    setActiveIndex,
    showIdentificationMarkers,
    markerItems,
    videoPlaybackStart,
    videoLastClick,
    darkenImageStyle,
    setVideoPlayedSeconds,
    resetMarkers
  } = useContext(PostDetailsContext);

  if (isLoading) return <ViolationSkeleton />;

  if (isError || !postData) {
    return <Error message="Failed to get post details" />;
  }

  return (
    <Grid
      container
      spacing={2}
      sx={{
        height: 'calc(100vh - 108px)' // Full viewport height minus any header offset
      }}
    >
      {/* Left Grid */}
      <Grid item xs={12} md={8} sx={{ height: '100%' }}>
        <Post
          postData={postData}
          setActiveIndex={setActiveIndex}
          evaluationProps={{
            showIdentificationMarkers,
            markerItems,
            videoPlaybackStart,
            videoLastClick,
            darkenImageStyle,
            setVideoPlayedSeconds,
            resetMarkers
          }}
        />
      </Grid>

      {/* Right Grid */}
      <Grid item xs={12} md={4} sx={{ height: '100%' }}>
        <ScrollableStack>
          <PostKeywordsDetails />
          <PostTranscript />
          <PostVisuals />
          <PostCompliance />
        </ScrollableStack>
      </Grid>
    </Grid>
  );
};

export default PostLayout;
