import { useNavigate } from 'react-router-dom';

import { Typography, Stack, Container } from '@mui/material';

import Button from '@/components/button/Button';

interface Props {
  error?: number;
  message?: string;
}

const Error = ({ error = 0, message }: Props) => {
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/'); // Fallback to the home page
    window.location.reload(); // Refresh the page
  };

  return (
    <Container>
      <Stack
        height="calc(100vh - 120px)"
        justifyContent="center"
        alignItems="center"
        spacing={5.5}
      >
        <img
          src={`${process.env.REACT_APP_S3_ASSETS}/empty-states/error.svg`}
          alt="Error"
        />
        <Stack gap={3} alignItems="center">
          <Stack padding="8px" gap={2.5} alignItems="center">
            <Typography
              variant="h4"
              sx={{
                fontWeight: 600
              }}
              textAlign="center"
            >
              Oops! Something went wrong...
            </Typography>
            <Typography
              align="center"
              sx={{
                fontSize: '1.1rem',
                maxWidth: '500px'
              }}
            >
              We encountered an unexpected issue. Our team has been notified and
              is working to resolve it.
            </Typography>
            {message && (
              <Typography
                align="center"
                variant="caption"
                sx={{
                  fontSize: '0.9rem',
                  fontStyle: 'italic',
                  color: 'text.secondary'
                }}
              >
                {message}
              </Typography>
            )}
          </Stack>
          {error > 0 && (
            <Typography
              align="center"
              variant="caption"
              sx={{
                fontSize: '0.85rem',
                color: 'text.disabled'
              }}
            >
              Error code: {error}
            </Typography>
          )}
          <Button
            onClick={handleBack}
            sx={{
              backgroundColor: 'black',
              borderRadius: 0,
              width: 'auto', // Prevent full width
              padding: '8px 24px', // Add padding for better appearance
              '&:hover': {
                backgroundColor: '#333333'
              }
            }}
            text="Back Home"
          />
        </Stack>
      </Stack>
    </Container>
  );
};

export default Error;
