import { KeyboardEvent, useState } from 'react';

import { AddCircle } from '@mui/icons-material';
import {
  IconButton,
  Stack,
  TextField,
  Typography,
  useTheme,
  Chip,
  InputAdornment
} from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';

import Button from '@/components/button';
import Dialog from '@/components/dialog';
import { KEYWORD_GROUP_REGEX } from '@/constants/regex';
import {
  useCreateKeywordGroup,
  useCreateKeywords
} from '@/hooks/queries/keywords';
import useAlert from '@/hooks/useAlert';
import { getUniqueKeywordsAndError } from '@/utils/keywords';
import { validateString } from '@/utils/text';

const initialErrors = {
  invalidGroupName: '',
  duplicatedKeywords: ''
};

const isGroupValid = (groupTitle: string) =>
  validateString(groupTitle, KEYWORD_GROUP_REGEX, 2);

interface CreateKeywordGroupsProps {
  isOpen: boolean;
  onClose: () => void;
}

const CreateKeywordGroups = ({ isOpen, onClose }: CreateKeywordGroupsProps) => {
  const alert = useAlert();
  const theme = useTheme();
  const queryClient = useQueryClient();

  const [groupName, setGroupName] = useState('');
  const [keywordsInput, setKeywordsInput] = useState('');
  const [newKeywordsList, setNewKeywordsListList] = useState<string[]>([]);
  const [error, setError] = useState(initialErrors);

  const { mutate: createKeywords } = useCreateKeywords(
    (res) => {
      const createdCount = res?.data.created.length;
      const notCreatedCount = res?.data.notCreated.length;

      let message = `"${groupName}" successfully created and ${createdCount} keywords successfully added to the group.`;
      if (notCreatedCount > 0) {
        message += ` ${notCreatedCount} keywords were not created.`;
      }

      alert.success(message);
      queryClient.invalidateQueries({
        queryKey: ['keywordGroups'],
        refetchType: 'all'
      });
    },
    (data) => {
      alert.error(data?.response?.data.message);
    }
  );

  const { mutate: createKeywordGroup } = useCreateKeywordGroup(
    (res?) => {
      alert.success(`"${res?.data?.title}" successfully created`);
      if (newKeywordsList.length > 0) {
        createKeywords({
          keywords: newKeywordsList,
          categoryId: res?.data?.id
        });
      } else {
        queryClient.invalidateQueries({
          queryKey: ['keywordGroups'],
          refetchType: 'all'
        });
      }
      onClose();
    },
    (data) => {
      alert.error(data?.response?.data.message);
    }
  );

  const handleChangeGroupName = ({
    target: { value }
  }: React.ChangeEvent<HTMLInputElement>) => {
    setGroupName(value);

    const isValid = isGroupValid(value);
    const isNotEmpty = value !== '';

    if (!isValid && isNotEmpty) {
      if (error.invalidGroupName === '')
        setError({
          ...error,
          invalidGroupName:
            'The title cannot contain special characters and must be at least 2 characters long'
        });
    } else if (error.invalidGroupName !== '') {
      setError({ ...error, invalidGroupName: '' });
    }
  };

  const handleAddKeywords = () => {
    if (keywordsInput === '') return;

    const { keywordsArray, keywordsError } = getUniqueKeywordsAndError(
      keywordsInput,
      newKeywordsList
    );

    if (error.duplicatedKeywords !== keywordsError)
      setError({ ...error, duplicatedKeywords: keywordsError || '' });
    setNewKeywordsListList(keywordsArray);
    setKeywordsInput('');
  };

  const handleKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') handleAddKeywords();
  };

  const handleDeleteChip = (keyword: string) => {
    const newKeywordsListArray: string[] = newKeywordsList.slice();
    setNewKeywordsListList(newKeywordsListArray.filter((e) => e !== keyword));
  };

  const handleSaveGroup = () => {
    createKeywordGroup({ title: groupName });
  };

  return (
    <Dialog
      maxWidth="md"
      fullWidth
      title="Create a new group"
      open={isOpen}
      onClose={onClose}
      dialogContent={
        <>
          <Stack gap={2} marginTop={1}>
            <TextField
              fullWidth
              label="Group title"
              value={groupName}
              onChange={handleChangeGroupName}
              error={Boolean(error.invalidGroupName)}
              helperText={error.invalidGroupName}
            />
            <TextField
              fullWidth
              label="Add keywords"
              error={Boolean(error.duplicatedKeywords)}
              helperText={
                error.duplicatedKeywords ||
                'Add keywords separated by comma with at least 2 characters'
              }
              onKeyDown={handleKeyDown}
              value={keywordsInput}
              onChange={(e) => setKeywordsInput(e.target.value)}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    <IconButton onClick={handleAddKeywords}>
                      <AddCircle />
                    </IconButton>
                  </InputAdornment>
                )
              }}
            />
            <Stack>
              <Typography>Included keywords:</Typography>
              <Stack
                sx={{ overflowY: 'auto' }}
                height={150}
                border={`1px solid ${theme.palette.grey[400]}`}
                borderRadius="4px"
                direction="row"
                gap={1}
                padding={1}
                flexWrap="wrap"
              >
                {newKeywordsList?.map((keyword) => (
                  <Chip
                    onDelete={() => handleDeleteChip(keyword)}
                    sx={{
                      backgroundColor: theme.palette.primary.light
                    }}
                    key={keyword}
                    label={keyword}
                    color="primary"
                  />
                ))}
              </Stack>
            </Stack>
          </Stack>
        </>
      }
      actions={
        <>
          <Button onClick={onClose} variant="text" text="cancel" />
          <Button
            onClick={handleSaveGroup}
            text="save"
            disabled={groupName === '' || !isGroupValid(groupName)}
          />
        </>
      }
    />
  );
};

export default CreateKeywordGroups;
