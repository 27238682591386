import { useMemo, useState } from 'react';
import { useParams } from 'react-router';

import { Typography, Stack } from '@mui/material';

import { useQueryClient } from '@tanstack/react-query';

import AutocompleteCreators from '@/components/autocompleteCreators/AutocompleteCreators';
import Button from '@/components/button';
import Dialog from '@/components/dialog/Dialog';
import {
  UpdateCreatorGroups,
  useFetchCreatorGroupsList
} from '@/hooks/queries/creators';
import useAlert from '@/hooks/useAlert';
import { CreatorGroups } from '@/models/creator';

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const INITIAL_ERRORS = {
  noGroupsSelected: ''
};

const AddCreatorToGroups = ({ isOpen, onClose }: Props) => {
  const { id } = useParams();
  const alert = useAlert();
  const queryClient = useQueryClient();

  const [groups, setGroups] = useState<CreatorGroups.Group[]>([]);
  const [errors, setErrors] = useState(INITIAL_ERRORS);

  const { data: initialGroups = [] } = useFetchCreatorGroupsList({
    id: Number(id)
  });

  const { mutate: updateCreatorGroups, isPending: isSubmitting } =
    UpdateCreatorGroups({
      id: Number(id),
      onSuccess: () => {
        alert.success(`Creator successfully added to the group(s)!`);
        queryClient.invalidateQueries({
          queryKey: ['creatorGroupsList', Number(id)],
          refetchType: 'all'
        });
        onClose();
      },
      onError: () =>
        alert.error(`Some error occurred while adding to the group(s)`)
    });

  const handleSelectGroup = (
    _: React.ChangeEvent<HTMLInputElement>,
    value: CreatorGroups.Group[]
  ) => {
    setGroups(value);
    if (errors.noGroupsSelected !== '') setErrors(INITIAL_ERRORS);
  };

  const handleSubmit = () => {
    if (groups.length === 0) {
      setErrors({
        ...errors,
        noGroupsSelected: 'Please select at least one creator group'
      });
      return;
    }

    const previousSelectedGroups = initialGroups
      .filter((group) => group.selected)
      .flatMap((group) => group.id);

    updateCreatorGroups({
      groupIds: [...previousSelectedGroups, ...groups.map((group) => group.id)]
    });
  };

  const groupsOptions = useMemo(
    () =>
      (initialGroups || [])
        .filter((group) => !group.selected)
        .map((group) => ({
          ...group,
          name: group.title,
          type: 'Groups'
        })),
    [initialGroups]
  );

  return (
    <Dialog
      open={isOpen}
      title="Add to group"
      fullWidth
      maxWidth="sm"
      maxHeight={400}
      dialogContent={
        <Stack marginTop={1}>
          <AutocompleteCreators
            value={groups}
            onChange={handleSelectGroup}
            creatorGroups={groupsOptions}
            label="Creator Groups"
          />
          {errors.noGroupsSelected && (
            <Typography color="error">{errors.noGroupsSelected}</Typography>
          )}
        </Stack>
      }
      actions={
        <>
          <Button variant="text" text="Cancel" onClick={onClose} />
          <Button onClick={handleSubmit} text="Add" loading={isSubmitting} />
        </>
      }
    />
  );
};

export default AddCreatorToGroups;
