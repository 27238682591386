import { AxiosPromise } from 'axios';

import { Keywords } from '@/models/keywords';

import { api } from '.';

export const getKeywordGroups = (): AxiosPromise<Keywords.List> =>
  api.get(`keywords/categories`);

export const getKeywordGroupById = (
  id: number
): AxiosPromise<Keywords.GroupDetails> => api.get(`keywords/categories/${id}`);

export const getKeywordsAutocomplete = (val: string) =>
  api.get(`keywords/autocomplete?s=${val}`);

export const createKeywordGroup = (payload: { title: string }) =>
  api.post(`keywords/categories`, payload);

export const createKeywords = (payload: {
  keywords: Array<string>;
  categoryId: number;
}) => api.post(`keywords`, payload);

export const deleteKeywordGroups = (payload: { categoryIds: string }) =>
  api.delete(`keywords/categories`, { data: payload });

export const deleteKeywords = (payload: {
  values: string;
  categoryId: number;
}) => api.delete(`keywords`, { data: payload });
