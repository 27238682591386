import { Dispatch } from 'react';

import { Stack, Typography } from '@mui/material';

import { PostDetailsProviderType } from '@/contexts/PostDetailsContext';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { Posts } from '@/models/posts';

import Card from '../card';
import CreatorDetails from '../creatorDetails';
import MediaDisplay from '../mediaDisplay';

interface EvaluationProps {
  showIdentificationMarkers: PostDetailsProviderType['showIdentificationMarkers'];
  markerItems: PostDetailsProviderType['markerItems'];
  videoPlaybackStart: PostDetailsProviderType['videoPlaybackStart'];
  videoLastClick: PostDetailsProviderType['videoLastClick'];
  darkenImageStyle: PostDetailsProviderType['darkenImageStyle'];
  setVideoPlayedSeconds: PostDetailsProviderType['setVideoPlayedSeconds'];
  resetMarkers: PostDetailsProviderType['resetMarkers'];
}

interface PostProps {
  postData: Posts.Details;
  setActiveIndex?: Dispatch<React.SetStateAction<number>>;
  evaluationProps?: EvaluationProps;
}

interface PostCreatorProps {
  postData: Posts.Details;
}

const PostCreator = ({ postData }: PostCreatorProps) => {
  const navigate = useWorkspaceNavigate();

  if (!postData)
    return <Typography variant="body2">Data not available</Typography>;

  return (
    <Card
      shadow
      content={<CreatorDetails data={postData} navigate={navigate} />}
    />
  );
};

const PostMedia = ({
  postData,
  setActiveIndex,
  evaluationProps
}: PostProps) => (
  <Card
    shadow
    sidePadding={false}
    style={{
      padding: 0,
      height: 'calc(100% - 72px)'
    }}
    content={
      <MediaDisplay
        data={postData}
        onMediaChange={setActiveIndex}
        showIdentificationMarkers={evaluationProps?.showIdentificationMarkers}
        markerItems={evaluationProps?.markerItems}
        videoPlaybackStart={evaluationProps?.videoPlaybackStart}
        videoLastClick={evaluationProps?.videoLastClick}
        filter={evaluationProps?.darkenImageStyle}
        onVideoProgress={evaluationProps?.setVideoPlayedSeconds}
        onMarkersReset={evaluationProps?.resetMarkers}
      />
    }
  />
);

const Post = ({ postData, setActiveIndex, evaluationProps }: PostProps) => (
  <Stack gap={2} sx={{ height: '100%' }}>
    <PostCreator postData={postData} />
    <PostMedia
      postData={postData}
      setActiveIndex={setActiveIndex}
      evaluationProps={evaluationProps}
    />
  </Stack>
);

export default Post;
