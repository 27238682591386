import { Fragment, useCallback, useContext, useMemo } from 'react';

import {
  Stack,
  Chip,
  useTheme,
  Tooltip,
  Typography,
  Link,
  Theme
} from '@mui/material';

import parse, { HTMLReactParserOptions, Element } from 'html-react-parser';

import Card from '@/components/card';
import { CHARS } from '@/constants/description';
import { PostDetailsContext } from '@/contexts/PostDetailsContext';
import { Platform, Posts } from '@/models/posts';
import { highlightWords } from '@/utils/text';
import { replaceNewLinesWithBreaks } from '@/utils/text/text';

type charType = 'must' | 'might' | 'not';

interface KeywordProps {
  keyword: string;
  post: Posts.Details | null | undefined;
  onClickKeyword: (_value: string) => void;
  selectedKeywords: Array<string>;
}

interface KeywordsStackProps {
  children: JSX.Element[];
}

interface HighlightedContentProps {
  content: string;
  mustAppear: number;
  mightAppear: number;
  theme: Theme;
  parseOptions?: HTMLReactParserOptions;
}

const Keyword = ({
  keyword,
  post,
  onClickKeyword,
  selectedKeywords
}: KeywordProps) => {
  const getKeywordTooltip = useCallback(
    (_keyword: string) => {
      const keywordGroup = (post?.evaluation.keywordGroups || []).find(
        (group) => group.keywords.includes(_keyword)
      );

      return keywordGroup?.title || '';
    },
    [post]
  );

  return (
    <Tooltip
      arrow
      key={`${keyword}-${Math.random()}`}
      placement="top"
      title={getKeywordTooltip(keyword)}
    >
      <Chip
        clickable
        size="small"
        color="primary"
        label={keyword}
        onClick={() => onClickKeyword(keyword)}
        variant={selectedKeywords.includes(keyword) ? 'filled' : 'outlined'}
      />
    </Tooltip>
  );
};

const KeywordsStack = ({ children }: KeywordsStackProps) => {
  const theme = useTheme();

  return (
    <Stack
      border={`1px solid ${theme.palette.grey[400]}`}
      borderRadius="4px"
      direction="row"
      gap={1}
      padding={1}
      flexWrap="wrap"
    >
      {children}
    </Stack>
  );
};

const options: HTMLReactParserOptions = {
  replace: (domNode) => {
    if (domNode instanceof Element) {
      const { attribs } = domNode;
      if (attribs?.href)
        return (
          <Link href={attribs.href} target="_blank">
            {attribs.href}
          </Link>
        );
    }
    return domNode;
  }
};

/**
 * HighlightedContent Component
 *
 * This component is designed to highlight specific sections of the content based on their importance.
 * It highlights sections as "must appear", "might appear", or "less likely to appear" using different styles and tooltips.
 *
 */
const HighlightedContent = ({
  content,
  mustAppear,
  mightAppear,
  theme,
  parseOptions
}: HighlightedContentProps) => {
  // Tracks the total character count processed so far
  let charCount = 0;

  /**
   * Determines the highlight type ('must', 'might', 'not') based on the character index.
   *
   * @param {number} index - The index of the character in the content.
   * @returns {charType} The type of highlight to apply to the character.
   */
  const getHighlightType = (index: number): charType => {
    if (index < mustAppear) return 'must';
    if (index < mustAppear + mightAppear) return 'might';
    return 'not';
  };

  /**
   * Returns the style object based on the highlight type.
   *
   * @param {charType} type - The type of highlight ('must', 'might', 'not').
   * @returns {object} The style object to be applied.
   */
  const getStyle = (type: charType): object => {
    switch (type) {
      case 'must':
        return {
          backgroundColor: `${theme.palette.success.main}40`, // Light green background
          cursor: 'pointer'
        };
      case 'might':
        return {
          backgroundColor: `${theme.palette.warning.main}33`, // Light yellow background
          cursor: 'pointer'
        };
      case 'not':
        return {
          backgroundColor: `${theme.palette.error.main}26`, // Light red background
          cursor: 'pointer'
        };
      default:
        return {};
    }
  };

  /**
   * Returns the tooltip text based on the highlight type.
   *
   * @param {charType} type - The type of highlight ('must', 'might', 'not').
   * @returns {string} The tooltip text to be displayed.
   */
  const getTooltipTitle = (type: charType): string => {
    switch (type) {
      case 'must':
        return 'This text is usually displayed directly';
      case 'might':
        return 'This text might be displayed directly, depending on device, browser and screen size.';
      case 'not':
        return "This text is usually only displayed after clicking 'read more'";
      default:
        return '';
    }
  };

  /**
   * Wraps a given text segment in a Tooltip with appropriate style and title.
   *
   */
  const wrapWithTooltip = (child: any, type: charType) => (
    <Tooltip placement="top" arrow title={getTooltipTitle(type)}>
      <span style={getStyle(type)}>{child}</span>
    </Tooltip>
  );

  /**
   * Custom replace function used by `html-react-parser` to process each node.
   *
   * @param {any} node - The current node being processed by the parser.
   * @returns {any} The processed node with highlights and tooltips, or undefined if not applicable.
   */
  const customReplace = (node: any): any => {
    // Checks if the node is a text node and has data (text content)
    if (node.type === 'text' && node.data) {
      const parts: React.ReactNode[] = [];
      let currentPart = '';
      let currentType = getHighlightType(charCount);

      // Iterates over each character in the text node
      for (let i = 0; i < node.data.length; i += 1) {
        const char = node.data[i];
        const newType = getHighlightType(charCount + i);

        // If the character type changes, push the current part and start a new one
        if (newType !== currentType) {
          parts.push(
            <Fragment key={charCount + i - currentPart.length}>
              {wrapWithTooltip(currentPart, currentType)}
            </Fragment>
          );
          currentPart = '';
          currentType = newType;
        }

        currentPart += char;
      }

      // Push the last part if any
      if (currentPart) {
        parts.push(
          <Fragment key={charCount + node.data.length - currentPart.length}>
            {wrapWithTooltip(currentPart, currentType)}
          </Fragment>
        );
      }

      // Update the total character count processed
      charCount += node.data.length;

      // Return the array of parts wrapped in a React fragment
      return <>{parts}</>;
    }

    return undefined;
  };

  // Parses the content and applies the custom replace function to highlight text
  return <>{parse(content, { ...parseOptions, replace: customReplace })}</>;
};

const DescriptionStack = ({
  description,
  platform,
  opt
}: {
  description: string;
  platform: Platform;
  opt: { sx: object; parseOptions: object };
}) => {
  const theme = useTheme();

  if (
    platform === 'youtube-livestream' ||
    platform === 'youtube-video' ||
    platform === 'youtube-short' ||
    platform === 'instagram-story'
  ) {
    return (
      <Stack>
        <Typography variant="body1" fontWeight="500">
          Description
        </Typography>
        <Typography sx={opt.sx} variant="caption">
          {parse(description, opt.parseOptions)}
        </Typography>
      </Stack>
    );
  }

  const { MUST_APPEAR, MIGHT_APPEAR } = CHARS[platform];

  return (
    <Stack>
      <Typography variant="body1" fontWeight="500">
        Description
      </Typography>
      <Typography sx={opt.sx} variant="caption">
        <HighlightedContent
          content={description}
          mustAppear={MUST_APPEAR}
          mightAppear={MIGHT_APPEAR}
          theme={theme}
          parseOptions={opt.parseOptions}
        />
      </Typography>
    </Stack>
  );
};

const TitleStack = ({
  title,
  platform
}: {
  title: string;
  platform: Platform;
}) => {
  const theme = useTheme();
  const parsedTitle = parse(title);

  if (platform !== 'youtube-short') {
    return (
      <Stack>
        <Typography variant="body1" fontWeight="500">
          Title
        </Typography>
        <Typography variant="caption">{parsedTitle}</Typography>
      </Stack>
    );
  }

  const { MUST_APPEAR, MIGHT_APPEAR } = CHARS[platform];

  return (
    <Stack>
      <Typography variant="body1" fontWeight="500">
        Title
      </Typography>
      <Typography variant="caption">
        <HighlightedContent
          content={title}
          mustAppear={MUST_APPEAR}
          mightAppear={MIGHT_APPEAR}
          theme={theme}
        />
      </Typography>
    </Stack>
  );
};

const PostKeywordsDetails = () => {
  const { data, selectedKeywords, keywordList, setSelectedKeywords } =
    useContext(PostDetailsContext);

  const handleClickKeyword = useCallback(
    (keyword: string) => {
      const newSelectedKeywords = [...selectedKeywords];

      const clickedInSelectedKeyword = newSelectedKeywords.includes(keyword);

      if (clickedInSelectedKeyword) {
        const keywordIndex = newSelectedKeywords.indexOf(keyword);
        newSelectedKeywords.splice(keywordIndex, 1);
      } else newSelectedKeywords.push(keyword);

      setSelectedKeywords(newSelectedKeywords);
    },
    [selectedKeywords]
  );

  const highlightedContent = useMemo(() => {
    const tags =
      data?.tags
        ?.map((item) =>
          !item.username.startsWith('@') ? `@${item.username}` : item.username
        )
        .join(' ') || '';
    const hashtags =
      data?.hashtags
        ?.map((item) => (!item.startsWith('#') ? `#${item}` : item))
        .join(' ') || '';
    const title = data?.title || '';
    const description = data?.description || '';

    const shouldHighlight = selectedKeywords.length > 0;

    const highlightedTags =
      shouldHighlight && tags ? highlightWords(tags, selectedKeywords) : tags;

    const highlightedHashtags =
      shouldHighlight && hashtags
        ? highlightWords(hashtags, selectedKeywords)
        : hashtags;

    const highlightedTitle =
      shouldHighlight && title
        ? highlightWords(title, selectedKeywords)
        : title;

    let highlightedDescription =
      shouldHighlight && description
        ? highlightWords(description, selectedKeywords)
        : description;

    // highlightedDescription = replaceUrlsWithLinks(highlightedDescription);
    highlightedDescription = replaceNewLinesWithBreaks(highlightedDescription);

    return {
      tags: highlightedTags,
      hashtags: highlightedHashtags,
      title: highlightedTitle,
      description: highlightedDescription
    };
  }, [data, selectedKeywords]);

  const hasBorder = useMemo(
    () =>
      highlightedContent.tags.includes('</b>') ||
      highlightedContent.hashtags.includes('</b>') ||
      highlightedContent.title.includes('</b>') ||
      highlightedContent.description.includes('</b>'),
    [highlightedContent]
  );

  const hasKeywords = keywordList.length > 0;

  const keywordStack = hasKeywords ? (
    <KeywordsStack>
      {keywordList.map((keyword: string) => (
        <Keyword
          key={keyword}
          keyword={keyword}
          post={data}
          onClickKeyword={() => handleClickKeyword(keyword)}
          selectedKeywords={selectedKeywords}
        />
      ))}
    </KeywordsStack>
  ) : (
    <></>
  );

  const createStack = (
    label: string,
    content: string,
    opt = { sx: {}, parseOptions: {} }
  ) =>
    content && (
      <Stack>
        <Typography variant="body1" fontWeight="500">
          {label}
        </Typography>
        <Typography sx={opt.sx} variant="caption">
          {parse(content, opt.parseOptions)}
        </Typography>
      </Stack>
    );

  if (!data) return <Typography variant="body2">Data not available</Typography>;

  const hasContent =
    data?.title ||
    data?.description ||
    data?.tags?.length ||
    data?.hashtags?.length ||
    keywordList.length;

  return hasContent ? (
    <Card
      sx={{ marginBottom: 2 }} // Replacing gap: 2 in PostLayout due to display block
      isCollapsible
      title={hasKeywords ? 'Keywords' : 'Details'}
      hasBorder={hasBorder}
      content={
        <Stack gap={2}>
          {keywordStack}
          {data?.title && (
            <TitleStack
              title={highlightedContent.title}
              platform={data.platform}
            />
          )}
          {data?.description && (
            <DescriptionStack
              description={highlightedContent.description}
              opt={{
                sx: { overflowY: 'auto', maxHeight: 240 },
                parseOptions: options
              }}
              platform={data.platform}
            />
          )}
          {createStack('Tags', highlightedContent.tags)}
          {createStack('Hashtags', highlightedContent.hashtags)}
        </Stack>
      }
    />
  ) : (
    <></>
  );
};

export default PostKeywordsDetails;
