import { Box, Typography } from '@mui/material';

import { makeStyles } from 'tss-react/mui';

interface PreviewColumnProps {
  previewUrl?: string;
}

const useStyles = makeStyles()(() => ({
  previewContainer: {
    height: '55px',
    width: '93.5px',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  previewImage: {
    objectFit: 'cover',
    borderRadius: '4px',
    height: '55px',
    width: '93.5px'
  },
  placeholderBox: {
    height: '55px',
    width: '93.5px',
    backgroundColor: '#f0f0f0',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  placeholderText: {
    lineHeight: '1.2',
    maxWidth: '80px',
    textAlign: 'center'
  }
}));

const PreviewColumn = ({ previewUrl }: PreviewColumnProps) => {
  const { classes } = useStyles();

  return (
    <Box paddingLeft={1}>
      {previewUrl ? (
        <img
          className={classes.previewImage}
          src={previewUrl}
          alt="Post Preview"
        />
      ) : (
        <Box className={classes.placeholderBox}>
          <Typography
            variant="caption"
            color="textSecondary"
            className={classes.placeholderText}
          >
            No preview
            <br />
            available
          </Typography>
        </Box>
      )}
    </Box>
  );
};

export default PreviewColumn;
