import { useMemo, useState } from 'react';

import { List } from '@mui/icons-material';
import { Typography } from '@mui/material';
import { GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';

import Page from '@/components/Page';
import Button from '@/components/button/Button';
import DataTable from '@/components/dataTable';
import NoRowsOverlay from '@/components/dataTable/customNoRowsOverlay/NoRowsOverlay';
import OnBoardingOverlay from '@/components/dataTable/customNoRowsOverlay/OnBoardingOverlay';
import Header from '@/components/header/Header';
import Toolbar from '@/components/toolbar/Toolbar';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants/table';
import { useFetchKeywordGroups } from '@/hooks/queries/keywords';
import useTableParams from '@/hooks/useTableParams';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { Keywords } from '@/models/keywords';
import { getPrettyDate } from '@/utils/datesAndTime';
import getRoute from '@/utils/getRoute/getRoute';
import nFormatter from '@/utils/nFormatter';
import Error from '@/views/misc/error';

import CreateKeywordGroups from '../create/CreateKeywordGroups';
import DeleteKeywordGroups from '../delete/DeleteKeywordGroups';

const defaultParams = {
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE,
  sort: undefined,
  order: undefined,
  name: undefined
};

const KeywordGroupsOnBoardingOverlay = () => (
  <OnBoardingOverlay
    icon={<List color="action" sx={{ width: 40, height: 40 }} />}
    title="Start list of Keyword groups"
    text="The keyword group feature enables you to effortlessly establish your keyword criteria for evaluations."
  />
);

const KeywordGroups = () => {
  const navigate = useWorkspaceNavigate();
  const { params, handlers } = useTableParams(
    getRoute.keywords.LIST(),
    defaultParams
  );

  const [selectedGroups, setSelectedGroups] = useState<GridRowSelectionModel>(
    []
  );
  const [isKeywordsDialogOpen, setKeywordsDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  const {
    data: keywordGroups = [],
    isLoading,
    isError
  } = useFetchKeywordGroups();

  const filteredGroups = useMemo(
    () =>
      keywordGroups.filter((item) =>
        item.title
          .toLowerCase()
          .includes(params.name ? params.name.toLowerCase() : '')
      ),
    [keywordGroups, params.name]
  );

  const columns = useMemo(
    () => [
      {
        field: 'title',
        headerName: 'Title',
        width: 160,
        flex: 1,
        renderCell: (params: GridRenderCellParams<Keywords.Group>) => (
          <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
            {params.row.title}
          </Typography>
        )
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        width: 140,
        flex: 1,
        renderCell: (params: GridRenderCellParams<Keywords.Group>) =>
          getPrettyDate(params.row.createdAt)
      },
      {
        field: 'updatedAt',
        headerName: 'Last edited',
        width: 140,
        flex: 1,
        renderCell: (params: GridRenderCellParams<Keywords.Group>) =>
          getPrettyDate(params.row.updatedAt || params.row.createdAt)
      },
      {
        field: 'totalItems',
        headerName: 'Items',
        width: 100,
        renderCell: (params: GridRenderCellParams<Keywords.Group>) => (
          <Typography>{nFormatter(params.row.keywords)}</Typography>
        )
      }
    ],
    [filteredGroups]
  );

  let emptyStateKeywordGroups = KeywordGroupsOnBoardingOverlay;

  if (params.name !== undefined) {
    emptyStateKeywordGroups = () => <NoRowsOverlay />;
  }

  if (isError)
    return (
      <Page>
        <Error message="Failed to get keyword groups" />
      </Page>
    );

  return (
    <>
      <Page
        header={
          <Header
            actions={
              <Button
                onClick={() => {
                  setKeywordsDialogOpen(true);
                }}
                text="add group"
              />
            }
            title="Keyword Groups"
          />
        }
        toolbar={
          <Toolbar
            isDeleteEnabled={selectedGroups.length > 0}
            onClickDelete={() => setIsDeleteDialogOpen(true)}
            onChangeSearch={handlers.onChangeSearch}
            showDeleteButton
            showSearchInput
            searchValue={params.name ? String(params.name) : ''}
            totalItems={filteredGroups?.length || 0}
          />
        }
        title="Keyword Groups"
      >
        <DataTable
          rowSelectionModel={selectedGroups}
          onRowSelectionModelChange={setSelectedGroups}
          rowHeight={80}
          rows={filteredGroups || []}
          checkboxSelection={true}
          columns={columns}
          loading={isLoading}
          onChangePageSize={handlers.onChangePageSize}
          onChangePage={handlers.onChangePage}
          onSortModelChange={handlers.onSortModelChange}
          onRowClick={(row) => navigate(getRoute.keywords.DETAIL(row.id))}
          sort={params.sort}
          order={params.order}
          page={params.page}
          size={params.size}
          noRowsOverlay={emptyStateKeywordGroups}
        />
      </Page>
      {isKeywordsDialogOpen && (
        <CreateKeywordGroups
          isOpen={isKeywordsDialogOpen}
          onClose={() => setKeywordsDialogOpen(false)}
        />
      )}
      {isDeleteDialogOpen && (
        <DeleteKeywordGroups
          isOpen={isDeleteDialogOpen}
          onClose={() => setIsDeleteDialogOpen(false)}
          selected={selectedGroups}
          clearSelection={() => {
            setSelectedGroups([]);
          }}
          groupsList={keywordGroups || []}
        />
      )}
    </>
  );
};

export default KeywordGroups;
