import { useContext, useEffect, useMemo, useState } from 'react';

import {
  Cancel,
  CheckCircle,
  Circle,
  Error,
  KeyboardArrowDown,
  Warning
} from '@mui/icons-material';
import {
  Stack,
  Typography,
  Divider,
  ButtonGroup,
  Button as MuiButton,
  FormControl,
  Select,
  MenuItem,
  SelectChangeEvent,
  TextField,
  AlertProps
} from '@mui/material';

import Alert from '@/components/alert';
import Button from '@/components/button';
import Card from '@/components/card';
import Checkbox from '@/components/checkbox';
import Dialog from '@/components/dialog';
import NotRelevantIcon from '@/components/icons/compliance/NotRelevantIcon';
import OrganicIcon from '@/components/icons/compliance/OrganicIcon';
import { PostDetailsContext } from '@/contexts/PostDetailsContext';
import {
  useCreateCompliance,
  useDeleteCompliance,
  useFetchPost,
  useUpdateCompliance
} from '@/hooks/queries/posts';
import useAlert from '@/hooks/useAlert';
import { Posts } from '@/models/posts';
import { getPrettyDateWithTime } from '@/utils/datesAndTime/datesAndTime';

const alertReturn = (
  rating: string,
  isRelevant: boolean | null,
  adType: Posts.adType
) => {
  let capitalizedAdType = adType
    ? adType.charAt(0).toUpperCase() + adType.slice(1)
    : '';

  capitalizedAdType =
    capitalizedAdType === 'Questionable'
      ? 'Possible Advertisement'
      : capitalizedAdType;

  if (adType === 'organic') {
    return {
      title: 'Organic',
      content: '',
      icon: <OrganicIcon fontSize="large" color="secondary" />
    };
  }

  if (isRelevant === false) {
    return {
      title: 'Not Relevant',
      content: '',
      icon: <NotRelevantIcon fontSize="large" color="primary" />
    };
  }

  const alerts: Record<string, AlertProps> = {
    questionable: {
      title: 'Questionable',
      content: capitalizedAdType,
      icon: <Warning color="warning" fontSize="large" />
    },
    violation: {
      title: 'Violation',
      content: capitalizedAdType,
      icon: <Error color="error" fontSize="large" />
    },
    compliant: {
      title: 'Compliant',
      content: capitalizedAdType,
      icon: <CheckCircle color="success" fontSize="large" />
    },
    default: {
      title: 'Default',
      content: capitalizedAdType,
      icon: <Cancel />
    }
  };

  return alerts[rating] || alerts.default;
};

const selectColor: Record<
  Posts.ComplianceType,
  'success' | 'warning' | 'error' | undefined
> = {
  compliant: 'success',
  questionable: 'warning',
  violation: 'error',
  unknown: undefined,
  '': undefined
};

const PostCompliance = () => {
  const { postId, evaluationId, data } = useContext(PostDetailsContext);
  const alert = useAlert();

  const [compliance, setCompliance] = useState<Posts.ComplianceType>('');
  const [reason, setReason] = useState('');
  const [isEditing, setEditing] = useState(false);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isRelevant, setIsRelevant] = useState<boolean | null>(null);
  const [adType, setAdType] = useState<Posts.adType>('');
  const [isRated, setIsRated] = useState(false);
  const [author, setAuthor] = useState<{ name: string; date: string }>({
    name: '',
    date: ''
  });

  const { data: unfilteredData, refetch: fetchUnfilteredData } = useFetchPost({
    id: postId,
    params: {
      evaluationId
    },
    enabled: false
  });

  const { mutate: createCompliance, isPending: isCreating } =
    useCreateCompliance(
      () => {
        fetchUnfilteredData();
        alert.success('The rating was successfully created!');
      },
      () => alert.error('Some error occurred while rating the compliance')
    );

  const { mutate: updateCompliance, isPending: isUpdating } =
    useUpdateCompliance(
      () => {
        setEditing(false);
        fetchUnfilteredData();
        alert.success('The rating was successfully updated!');
      },
      () => alert.error('Some error occurred while rating the compliance')
    );

  const { mutate: deleteCompliance, isPending: isDeleting } =
    useDeleteCompliance(
      () => {
        fetchUnfilteredData();
        alert.success('The rating was successfully deleted!');
      },
      () => {
        alert.error('Some error occurred while deleting the rating');
      },
      () => {
        setDialogOpen(false);
      }
    );

  useEffect(() => {
    if (data?.evaluation?.compliance) {
      fillStates(data);
    }
  }, [data]);

  useEffect(() => {
    if (unfilteredData?.evaluation?.compliance) {
      fillStates(unfilteredData);
    }
  }, [unfilteredData]);

  const fillStates = (data: Posts.Details) => {
    const {
      author: evaluationAuthor,
      isRelevant: evaluationIsRelevant = null,
      reason: evaluationReason,
      compliance: evaluationCompliance,
      adType: evaluationAdType,
      isRated: evaluationIsRated
    } = data.evaluation.compliance;
    setIsRated(evaluationIsRated);
    setIsRelevant(evaluationIsRelevant);
    setReason(evaluationReason);
    setCompliance(
      evaluationCompliance === 'unknown' ? '' : evaluationCompliance
    );
    setAdType(evaluationAdType);
    setAuthor(evaluationAuthor);
  };

  const handleClickSave = () => {
    const payload = {
      postId,
      evaluationId,
      reason,
      isRelevant: isRelevant || isRelevant === null,
      compliance: isRelevant === false ? '' : compliance,
      adType: isRelevant === false ? '' : adType
    };
    return isEditing ? updateCompliance(payload) : createCompliance(payload);
  };

  const handleAdTypeChange = (button: Posts.adType) => {
    if (button === 'organic') {
      setCompliance('');
    }
    setAdType(button);
  };

  const handleDeleteRating = () => {
    deleteCompliance({ postId, evaluationId });
  };

  const handleComplianceChange = (event: SelectChangeEvent) => {
    setCompliance(event.target.value as Posts.ComplianceType);
  };

  const handleCancel = () => {
    if (unfilteredData) {
      fillStates(unfilteredData);
    } else if (data) {
      fillStates(data);
    }
    setEditing(false);
  };

  const complianceOptions = [
    { value: 'compliant', label: 'Compliant', color: 'success' },
    { value: 'questionable', label: 'Questionable', color: 'warning' },
    { value: 'violation', label: 'Violation', color: 'error' }
  ];

  const ComplianceAlert = useMemo(
    () => alertReturn(compliance || '', isRelevant, adType),
    [compliance, isRelevant, adType]
  );

  if (!data) return <Typography variant="body2">Data not available</Typography>;

  const isSaveButtonDisabled =
    isRelevant !== false &&
    adType !== 'organic' &&
    (compliance === 'unknown' || compliance === '');

  return (
    <>
      <Card
        title="Rating"
        content={
          <Stack gap={2}>
            {!isRated || isEditing ? (
              <>
                <Checkbox
                  value={isRelevant}
                  checked={isRelevant === false}
                  onChange={(event) => setIsRelevant(!event.target.checked)}
                  label={<Typography variant="body2">Not relevant</Typography>}
                />

                {(isRelevant || isRelevant === null) && (
                  <>
                    <Divider />
                    <Typography variant="body2">
                      What type of content is displayed in the post?
                    </Typography>
                    <ButtonGroup
                      fullWidth
                      variant="outlined"
                      sx={{
                        flexWrap: 'wrap', // Allow buttons to wrap
                        '& .MuiButton-root': {
                          flex: '1 1 calc(33.333% - 8px)', // Make buttons responsive
                          textTransform: 'none',
                          fontSize: { xs: '0.7rem', sm: '0.8rem' }, // Adjust font size for small screens
                          minWidth: 100 // Ensure a minimum button width
                        }
                      }}
                    >
                      {['advertisement', 'questionable', 'organic'].map(
                        (type) => (
                          <MuiButton
                            key={type}
                            onClick={() =>
                              handleAdTypeChange(type as Posts.adType)
                            }
                            variant={adType === type ? 'contained' : 'outlined'}
                          >
                            {type.charAt(0).toUpperCase() + type.slice(1)}
                          </MuiButton>
                        )
                      )}
                    </ButtonGroup>
                    {adType !== 'organic' && (
                      <>
                        <Typography variant="body2">
                          Does this video comply with the advertising rules?
                        </Typography>
                        <FormControl fullWidth>
                          <Select
                            value={compliance}
                            IconComponent={KeyboardArrowDown}
                            onChange={handleComplianceChange}
                            color={selectColor[compliance]}
                          >
                            {complianceOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                <Stack
                                  gap={1}
                                  direction="row"
                                  alignItems="center"
                                >
                                  <Circle
                                    color={
                                      option.color as
                                        | 'success'
                                        | 'warning'
                                        | 'error'
                                    }
                                    fontSize="inherit"
                                  />
                                  <Typography>{option.label}</Typography>
                                </Stack>
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </>
                    )}
                  </>
                )}
                <Stack gap="4px">
                  <Typography variant="subtitle2">Why?</Typography>
                  <TextField
                    value={reason}
                    onChange={(event) => setReason(event.target.value)}
                    label="Optional"
                  />
                </Stack>
              </>
            ) : (
              <>
                <Alert
                  sx={{
                    backgroundColor: '#EFEFEF',
                    color: '#000',
                    alignItems: ComplianceAlert.content === '' ? 'end' : ''
                  }}
                  title={ComplianceAlert.title}
                  icon={ComplianceAlert.icon}
                  content={ComplianceAlert.content}
                />
                <Stack spacing={1}>
                  <Typography variant="body2" color="textSecondary">
                    {reason}
                  </Typography>
                  <Typography variant="body2" color="textSecondary">{`${
                    author?.name
                  }, ${getPrettyDateWithTime(author?.date)}`}</Typography>
                </Stack>
              </>
            )}
          </Stack>
        }
        actions={
          !isRated || isEditing ? (
            <>
              {isEditing && (
                <Button
                  variant="text"
                  text="cancel"
                  disabled={isUpdating || isCreating}
                  onClick={handleCancel}
                />
              )}
              <Button
                disabled={isSaveButtonDisabled}
                text={!isRated ? 'rate post' : 'save rating'}
                loading={isUpdating || isCreating}
                onClick={handleClickSave}
              />
            </>
          ) : (
            <>
              <Button
                variant="text"
                text="remove"
                onClick={() => setDialogOpen(true)}
              />
              <Button text="edit" onClick={() => setEditing(true)} />
            </>
          )
        }
      />
      <Dialog
        open={isDialogOpen}
        title="Remove rating"
        dialogContent={
          <Typography>
            Are you sure you want to remove the rating from this item?
          </Typography>
        }
        actions={
          <>
            <Button
              variant="text"
              text="cancel"
              disabled={isDeleting}
              onClick={() => setDialogOpen(false)}
            />
            <Button
              color="error"
              loading={isDeleting}
              text="delete"
              onClick={handleDeleteRating}
            />
          </>
        }
      />
    </>
  );
};

export default PostCompliance;
