import axios, { AxiosRequestConfig, InternalAxiosRequestConfig } from 'axios';

import fire from '@/fire';
import { UnknownObject } from '@/models/misc';
import LocalStorageService from '@/services/LocalStorageService';
import getRoute from '@/utils/getRoute';

let isRefreshing = false;
let refreshPromise: Promise<string> | null = null;
const apiURL = process.env.REACT_APP_BACKEND_API_URL;

export const getHttpConfig = (
  config: AxiosRequestConfig
): AxiosRequestConfig => ({
  ...config,
  headers: {
    ...config.headers,
    'Content-Type': 'application/json',
    Authorization: `Bearer ${LocalStorageService.getItem('token')}`
  }
});

export const api = axios.create({ baseURL: apiURL });

export const decomposeParams = (params?: UnknownObject) =>
  params
    ? `?${Object.entries(params)
        .filter(([, value]) => ![undefined, null].includes(value))
        .map(([key, value]) => `${key}=${value}`)
        .join('&')}`
    : '';

const refreshToken = async (): Promise<string> =>
  new Promise((resolve, reject) => {
    const unsubscribe = fire.auth().onIdTokenChanged((user: any) => {
      unsubscribe(); // Cleanup listener after first token change
      if (user) {
        const { Aa: newToken } = user;
        LocalStorageService.setItem('token', newToken);
        resolve(newToken);
      } else {
        reject(new Error('User not authenticated'));
      }
    });

    // Add timeout to prevent hanging
    setTimeout(() => {
      unsubscribe();
      reject(new Error('Token refresh timeout'));
    }, 10000);
  });

api.interceptors.request.use((config) => ({
  ...config,
  ...(getHttpConfig(config) as InternalAxiosRequestConfig<unknown>)
}));

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;

    // Prevent infinite retry loops
    if (originalRequest.retry) {
      return Promise.reject(error);
    }

    if (error.response?.status === 401) {
      originalRequest.retry = true;

      try {
        if (!isRefreshing) {
          isRefreshing = true;
          refreshPromise = refreshToken();
        }

        // Wait for the existing refresh promise or create a new one
        const newToken = await refreshPromise;

        // Reset refresh state after successful token refresh
        if (isRefreshing) {
          isRefreshing = false;
          refreshPromise = null;
        }

        // Update the token in the failed request and retry
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return api.request(originalRequest);
      } catch (refreshError) {
        // Reset refresh state
        isRefreshing = false;
        refreshPromise = null;

        // Redirect to logout on refresh failure
        window.location.href = getRoute.auth.LOGOUT();
        return Promise.reject(refreshError);
      }
    }

    return Promise.reject(error);
  }
);
