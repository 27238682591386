import { useCallback } from 'react';

import { GridSortModel } from '@mui/x-data-grid-pro';

import { DEFAULT_PAGE } from '@/constants/table';
import { useUrlParams } from '@/hooks/urlParams';
import { URLParams } from '@/models/misc';

const useTableParams = (routePath: string, defaultParams: URLParams) => {
  const { params, updateParams, paramsInTheUrl } = useUrlParams(
    routePath,
    defaultParams
  );

  const handleChangePageSize = useCallback(
    (newSize: string) => {
      updateParams({ size: newSize });
    },
    [params]
  );

  const handleChangePage = useCallback(
    (newPage: string) => {
      updateParams({ page: newPage });
    },
    [params]
  );

  const handleSortModelChange = useCallback(
    (params: GridSortModel) => {
      const { sort: newOrder, field: newSort } = params[0] || {};
      updateParams({
        sort: newSort,
        order: newOrder
      });
    },
    [params]
  );

  const handleChangeSearch = useCallback(
    ({ target: { value } }: React.ChangeEvent<HTMLInputElement>) => {
      updateParams({
        name: value.replace(/^\s+/g, ''),
        page: DEFAULT_PAGE
      });
    },
    [params]
  );

  return {
    paramsInTheUrl,
    params,
    handlers: {
      onChangePageSize: handleChangePageSize,
      onChangePage: handleChangePage,
      onSortModelChange: handleSortModelChange,
      onChangeSearch: handleChangeSearch
    },
    updateParams
  };
};

export default useTableParams;
