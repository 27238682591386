import { ReactNode } from 'react';

import { ChevronRight } from '@mui/icons-material';
import ArrowBackIosNewIcon from '@mui/icons-material/ArrowBackIosNew';
import {
  Typography,
  Link,
  Breadcrumbs,
  IconButton,
  Divider,
  Stack
} from '@mui/material';

interface Props {
  actions?: JSX.Element;
  breadcrumbsList?: Array<{
    name: string;
    onClick: () => void;
  }>;
  isSmallHeader?: boolean;
  isSmallTitle?: boolean;
  onClickReturn?: () => void;
  showDivider?: boolean;
  title: ReactNode;
  sx?: object;
  mb?: boolean;
}

const Header = ({
  actions,
  breadcrumbsList = [],
  isSmallHeader = false,
  isSmallTitle = false,
  onClickReturn,
  showDivider = false,
  title,
  mb = false,
  sx
}: Props) => {
  const returnButtonSize = isSmallTitle ? 'small' : 'medium';
  const titleSize = isSmallTitle ? 'h5' : 'h4';

  return (
    <Stack sx={sx} mb={mb ? 2 : 0} data-testid="header" gap={1}>
      <Stack justifyContent="space-between" direction="row">
        <Stack gap={1}>
          {breadcrumbsList.length > 0 && (
            <Breadcrumbs
              aria-label="breadcrumb"
              data-testid="header-breadcrumbs"
              separator={<ChevronRight fontSize="small" />}
            >
              {breadcrumbsList.map((item, index) => (
                <Link
                  color="inherit"
                  component="button"
                  key={index}
                  onClick={item.onClick}
                >
                  {item.name}
                </Link>
              ))}
              <Typography
                fontWeight="bold"
                color="textPrimary"
                maxWidth="200px"
                noWrap
              >
                {title}
              </Typography>
            </Breadcrumbs>
          )}
          {!isSmallHeader && (
            <Stack gap={1} direction="row" alignItems="center">
              {onClickReturn && (
                <IconButton
                  color="inherit"
                  data-testid="header-return-btn"
                  onClick={onClickReturn}
                  size={returnButtonSize}
                >
                  <ArrowBackIosNewIcon fontSize={returnButtonSize} />
                </IconButton>
              )}
              {title && (
                <Typography
                  data-testid="header-title"
                  noWrap
                  variant={titleSize}
                  onClick={onClickReturn}
                  sx={{ cursor: onClickReturn ? 'pointer' : 'auto' }}
                >
                  {title}
                </Typography>
              )}
            </Stack>
          )}
        </Stack>
        {actions && (
          <Stack
            gap={1}
            direction="row"
            alignItems="center"
            justifyContent="flex-start"
            flexShrink="0"
            data-testid="header-actions"
            ml="auto"
          >
            {actions}
          </Stack>
        )}
      </Stack>
      {showDivider && <Divider data-testid="header-divider" />}
    </Stack>
  );
};

export default Header;
