import { forwardRef } from 'react';

import {
  Button as MuiButton,
  ButtonProps,
  CircularProgress
} from '@mui/material';

interface Props extends ButtonProps {
  text: string;
  loading?: boolean;
}

const Button = forwardRef<HTMLButtonElement, Props>(
  (
    {
      text,
      loading = false,
      color = 'primary',
      variant = 'contained',
      disabled = false,
      fullWidth = false,
      size = 'medium',
      startIcon,
      endIcon,
      onClick,
      ...props
    },
    ref
  ) => (
    <MuiButton
      ref={ref}
      color={color}
      variant={variant}
      disabled={loading ? true : disabled}
      onClick={onClick}
      fullWidth={fullWidth}
      size={size}
      startIcon={startIcon}
      endIcon={endIcon}
      {...props}
    >
      {loading ? (
        <CircularProgress
          size="24px"
          color="primary"
          data-testid="buttonLoading"
        />
      ) : (
        text
      )}
    </MuiButton>
  )
);

Button.displayName = 'Button';

export default Button;
