import { useQuery } from '@tanstack/react-query';

import getWorkspaces from '@/api/misc';
import { getWorkspaceStatistics } from '@/api/workspaces';
import { Workspace } from '@/models/workspace';
import { Workspaces } from '@/models/workspaces';

type WorkspacesProps = {
  onSuccess?: (_data: Workspace[]) => void;
  onError?: (_error: unknown) => void;
};

export const useFetchWorkspaces = ({ onSuccess, onError }: WorkspacesProps) =>
  useQuery({
    queryKey: ['workspaces'],
    queryFn: async () => {
      const result = await getWorkspaces();
      return result.data;
    },
    meta: {
      onSuccess,
      onError
    }
  });

type WorkspaceStatisticsProps = {
  onSuccess?: (_data: Workspaces.Statistics) => void;
  onError?: (_error: unknown) => void;
  params: Workspaces.StatisticsParams;
};

export const useFetchWorkspaceStatistics = ({
  onSuccess,
  onError,
  params
}: WorkspaceStatisticsProps) =>
  useQuery({
    queryKey: ['workspaceStatistics', params],
    queryFn: async () => {
      const result = await getWorkspaceStatistics(params);
      return result.data;
    },
    meta: {
      onSuccess,
      onError
    }
  });
