import { useEffect, useMemo } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ParamsInTheUrl, URLParams } from '@/models/misc';
import LocalStorageService, {
  LOCAL_STORAGE_KEY
} from '@/services/LocalStorageService';

const LOCAL_STORAGE_URL_PARAMS_KEY = 'urlParams';

/**
 * Constructs a URL path with URL search params stored in localStorage
 * @param {string} path - The base path of the URL
 * @returns {string} - The full URL path with search params appended
 */
export const getPathWithLocalParams = (path: string): string => {
  // Create a key name for the localStorage item containing the URL search params
  const localStorageKey = `${LOCAL_STORAGE_URL_PARAMS_KEY}_${path}`;

  // Get the URL search params from localStorage, or an empty string if none exist
  const localStorageParams = LocalStorageService.getItem(localStorageKey);

  // Parse the URL search params string into a URLSearchParams object
  const localSearchParams = new URLSearchParams(
    localStorageParams ? JSON.parse(localStorageParams) : ''
  );

  // Combine the base URL path and URL search params into a full URL
  return `${path}?${localSearchParams.toString()}`;
};

/**
 * A custom React hook that allows for reactive manipulation of the URL search params
 * @param {string} path - The path to which the URL search params belong
 * @param {URLParams} defaultParams - The default URL search params to be used. All reactive properties must be initialised (e.g. order: undefined)
 * @returns {URLParams} - An object containing the reactive URL search params and an updater function to change them
 */
export const useUrlParams = (path: string, defaultParams: URLParams) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const localStorageKey = `${LOCAL_STORAGE_URL_PARAMS_KEY}_${path}`;

  useEffect(() => {
    // Remove any URL search params from localStorage that do not belong to the current entity
    Object.keys(localStorage).forEach((key) => {
      const keyEntity = key.replace(`${LOCAL_STORAGE_KEY}-urlParams_`, '');
      if (key.includes('urlParams') && !path.includes(keyEntity))
        localStorage.removeItem(key);
    });
  }, [localStorageKey]);

  /**
   * Compute the current URL search params as a reactive object
   * @returns {URLParams} - A reactive object representing the current URL search params
   */
  const params = useMemo(() => {
    // Create a new object based on the defaultParams object with any existing search params values
    const newParams: URLParams = { ...defaultParams };
    Object.keys(defaultParams).forEach((key) => {
      newParams[key] = searchParams.get(key) || defaultParams[key];
    });

    return newParams;
  }, [defaultParams, searchParams]);

  /**
   * Update the current URL search params with the given object
   * @param {URLParams} newParams - An object representing the new URL search params to set
   */
  const updateParams = (newParams: URLParams): void => {
    // Update the URL search params based on the new object
    Object.keys(newParams).forEach((key) => {
      if (newParams[key] !== undefined && newParams[key] !== '')
        searchParams.set(key, `${newParams[key]}`);
      else searchParams.delete(key);
    });

    // Set the new URL search params and save them to localStorage
    setSearchParams(searchParams);
    LocalStorageService.setItem(
      localStorageKey,
      JSON.stringify(Array.from(searchParams.entries()))
    );
  };

  // Returns an object with all params in the url and their values
  const paramsInTheUrl = useMemo(() => {
    const newParams: ParamsInTheUrl = {};

    searchParams.forEach((value, key) => {
      newParams[key] = value;
    });

    return newParams;
  }, [searchParams]);

  // IMPORTANT: All necessary reactive config properties must be initialised if they are to work as reactive elements

  // Return the reactive URL search params and the updater function

  return { params, updateParams, paramsInTheUrl };
};
