import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Add } from '@mui/icons-material';
import { Chip, Skeleton, Tooltip, Typography } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';

import { useQueryClient } from '@tanstack/react-query';

import Page from '@/components/Page';
import Button from '@/components/button/Button';
import DataTable from '@/components/dataTable';
import Header from '@/components/header/Header';
import PLATFORM_MAPPER from '@/constants/platformMapper';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants/table';
import {
  useDeleteCreator,
  useFetchCreator,
  useFetchCreatorsPosts,
  useUpdateCreator
} from '@/hooks/queries/creators';
import { getPathWithLocalParams } from '@/hooks/urlParams';
import useAlert from '@/hooks/useAlert';
import useTableParams from '@/hooks/useTableParams';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { Creator } from '@/models/creator';
import { Platform } from '@/models/posts';
import { getPrettyDate } from '@/utils/datesAndTime';
import getRoute from '@/utils/getRoute/getRoute';
import BooleanColumn from '@/views/evaluations/detail/body/_partials/BooleanColumn';
import PreviewColumn from '@/views/evaluations/detail/body/_partials/PreviewColumn';
import Error from '@/views/misc/error';

import DeleteDialog from '../delete/DeleteCreator';
import EditDialog from '../edit/EditCreator';
import Accounts from './_partials/Accounts';
import AddCreatorToGroups from './_partials/AddCreatorToGroups';
import MergeCreators from './_partials/MergeCreators';
import Profile from './_partials/Profile';

const defaultParams = {
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE
};

const CreatorDetail = () => {
  const { id: creatorId } = useParams();
  const navigate = useWorkspaceNavigate();
  const queryClient = useQueryClient();
  const alert = useAlert();
  const numericCreatorId = Number(creatorId);

  const { params, handlers } = useTableParams(
    getRoute.creators.DETAIL(numericCreatorId, defaultParams),
    defaultParams
  );

  const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [isAddToGroupsDialogOpen, setIsAddToGroupsDialogOpen] = useState(false);
  const [isMergeCreatorsDialogOpen, setIsMergeCreatorsDialogOpen] =
    useState(false);

  const {
    data: creator,
    isLoading: isLoadingCreator,
    isError: isErrorCreator
  } = useFetchCreator({ id: numericCreatorId });

  const { data: creatorPosts, isLoading: isLoadingCreatorPosts } =
    useFetchCreatorsPosts({
      id: numericCreatorId,
      params: { ...params }
    });

  const { mutate: deleteCreator, isPending: isDeleting } = useDeleteCreator({
    onSuccess: () => {
      alert.success(`${creator?.name} was successfully deleted!`);
      queryClient.invalidateQueries({
        queryKey: ['posts'],
        refetchType: 'all'
      });
      queryClient.invalidateQueries({
        queryKey: ['creators'],
        refetchType: 'all'
      });
      navigate(getRoute.creators.LIST());
    },
    onError: () => alert.error(`Some error occurred while deleting the creator`)
  });

  const { mutate: updateCreator, isPending: isUpdating } = useUpdateCreator({
    id: numericCreatorId,
    onSuccess: () => {
      alert.success(`Creator successfully updated!`);
      queryClient.invalidateQueries({
        queryKey: ['creator', numericCreatorId],
        refetchType: 'all'
      });
      queryClient.invalidateQueries({
        queryKey: ['posts'],
        refetchType: 'all'
      });
      queryClient.invalidateQueries({
        queryKey: ['creatorAvatars', numericCreatorId],
        refetchType: 'all'
      });
      queryClient.invalidateQueries({
        queryKey: ['creatorsPosts', numericCreatorId],
        refetchType: 'all'
      });
      queryClient.invalidateQueries({
        queryKey: ['creators'],
        refetchType: 'all'
      });
      setIsEditDialogOpen(false);
    },
    onError: () => alert.error(`Some error occurred while updating the creator`)
  });

  const handleClickDelete = () => {
    setIsEditDialogOpen(false);
    setIsDeleteDialogOpen(true);
  };

  const columns = useMemo(
    () => [
      {
        sortable: false,
        field: 'previewUrl',
        headerName: 'Preview',
        minWidth: 130,
        maxWidth: 130,
        flex: 1,
        renderCell: (params: GridRenderCellParams<Creator.Post>) => (
          <PreviewColumn previewUrl={params.row.previewUrl} />
        )
      },
      {
        field: 'title',
        headerName: 'Title',
        width: 150,
        renderCell: (params: GridRenderCellParams<Creator.Post>) => (
          <Tooltip title={params.row.title || 'No title available'}>
            <Typography
              style={{
                maxHeight: '50px',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
            >
              {params.row.title || <>—</>}
            </Typography>
          </Tooltip>
        )
      },
      {
        sortable: false,
        field: 'username',
        headerName: 'Account',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<Creator.Post>) => (
          <Tooltip title={params.row.username || 'No username available'}>
            <Typography
              style={{
                maxHeight: '50px',
                textOverflow: 'ellipsis',
                overflow: 'hidden'
              }}
            >
              {params.row.username || <>—</>}
            </Typography>
          </Tooltip>
        )
      },
      {
        sortable: false,
        field: 'platform',
        headerName: 'Platform',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<Creator.Post>) => (
          <Chip label={PLATFORM_MAPPER[params.row.platform as Platform]} />
        )
      },
      {
        sortable: false,
        field: 'uploadDate',
        headerName: 'Publication Date',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<Creator.Post>) => (
          <Typography variant="body2">
            {getPrettyDate(params.row.uploadDate)}
          </Typography>
        )
      },
      {
        sortable: false,
        field: 'platformDisclosure',
        headerName: 'Sponsored',
        minWidth: 150,
        flex: 1,
        renderCell: (params: GridRenderCellParams<Creator.Post>) => (
          <BooleanColumn value={params.row.platformDisclosure} />
        )
      }
    ],
    [creatorPosts]
  );

  if (isErrorCreator)
    return (
      <Page>
        <Error message="Failed to get creator details" />
      </Page>
    );

  return (
    <Page
      title={creator?.name || 'Creator detail page'}
      header={
        <Header
          isSmallHeader
          actions={
            <Button
              onClick={() => setIsAddToGroupsDialogOpen(true)}
              startIcon={<Add />}
              text="Add to group"
              size="small"
            />
          }
          breadcrumbsList={[
            {
              name: 'Creators',
              onClick: () =>
                navigate(getPathWithLocalParams(getRoute.creators.LIST()))
            }
          ]}
          onClickReturn={() =>
            navigate(getPathWithLocalParams(getRoute.creators.LIST()))
          }
          title={
            isLoadingCreator ? (
              <Skeleton variant="text" width="200px" />
            ) : (
              creator?.name
            )
          }
        />
      }
    >
      <>
        <Profile
          name={creator?.name || ''}
          avatarUrl={creator?.avatarUrl || ''}
          creationDate={creator?.creationDate || ''}
          showSkeleton={isLoadingCreator}
          onClickEdit={() => setIsEditDialogOpen(true)}
          onClickMerge={() => setIsMergeCreatorsDialogOpen(true)}
        />
        <Accounts accounts={creator?.accounts} />
        <DataTable
          rowHeight={85}
          rows={creatorPosts?.results || []}
          rowCount={creatorPosts?.totalItems}
          columns={columns}
          loading={isLoadingCreatorPosts}
          checkboxSelection={false}
          paginationMode="server"
          onChangePageSize={handlers.onChangePageSize}
          onChangePage={handlers.onChangePage}
          page={params.page}
          size={params.size}
          onRowClick={(row) =>
            navigate(getRoute.creators.POST(numericCreatorId, row.id))
          }
          disableColumnMenu
        />
        {isEditDialogOpen && (
          <EditDialog
            creator={creator}
            isOpen={isEditDialogOpen}
            onClose={() => {
              queryClient.invalidateQueries({
                queryKey: ['creatorAvatars', numericCreatorId],
                refetchType: 'all'
              });
              setIsEditDialogOpen(false);
            }}
            onSubmit={(data) => updateCreator(data)}
            onClickDelete={handleClickDelete}
            isUpdating={isUpdating}
          />
        )}
        {isMergeCreatorsDialogOpen && creator && (
          <MergeCreators
            creator={creator}
            isOpen={isMergeCreatorsDialogOpen}
            onClose={() => setIsMergeCreatorsDialogOpen(false)}
          />
        )}
        {isAddToGroupsDialogOpen && (
          <AddCreatorToGroups
            isOpen={isAddToGroupsDialogOpen}
            onClose={() => setIsAddToGroupsDialogOpen(false)}
          />
        )}
        {isDeleteDialogOpen && creator && (
          <DeleteDialog
            name={creator?.name}
            isOpen={isDeleteDialogOpen}
            onClose={() => {
              setIsEditDialogOpen(true);
              setIsDeleteDialogOpen(false);
            }}
            onSubmit={() => deleteCreator({ ids: [numericCreatorId] })}
            isDeleting={isDeleting}
          />
        )}
      </>
    </Page>
  );
};

export default CreatorDetail;
