import { useParams } from 'react-router';

import { Typography } from '@mui/material';
import { GridRowSelectionModel } from '@mui/x-data-grid';

import { useQueryClient } from '@tanstack/react-query';

import Button from '@/components/button';
import Dialog from '@/components/dialog';
import { useDeleteCreatorsFromGroup } from '@/hooks/queries/creatorGroups';
import useAlert from '@/hooks/useAlert';
import { CreatorGroups } from '@/models/creator';

interface Props {
  isOpen: boolean;
  onClose: () => void;
  clearSelection: () => void;
  selected: GridRowSelectionModel;
  creatorsList: Array<CreatorGroups.DetailsItem>;
}

const RemoveCreatorFromGroup = ({
  isOpen,
  onClose,
  selected,
  clearSelection,
  creatorsList
}: Props) => {
  const alert = useAlert();
  const queryClient = useQueryClient();
  const id = Number(useParams().id);

  const { mutate: removeCreators, isPending: isSubmitting } =
    useDeleteCreatorsFromGroup({
      id,
      onSuccess: () => {
        alert.success(`${creatorsLabel} successfully removed from the group!`);
        queryClient.invalidateQueries({
          queryKey: ['creatorGroup'],
          refetchType: 'all'
        });
        queryClient.invalidateQueries({
          queryKey: ['creatorsAutocomplete'],
          refetchType: 'all'
        });
        onClose();
        clearSelection();
      },
      onError: () =>
        alert.error(
          `Some error occurred while removing the ${creatorsLabel.toLowerCase()}`
        )
    });

  const handleSubmit = () => {
    removeCreators({ creators: selected });
  };

  const creatorsLabel = selected.length === 1 ? 'Creator' : 'Creators';

  return (
    <Dialog
      title={`Remove ${creatorsLabel}`}
      dialogContent={
        selected.length === 1 ? (
          <Typography>
            Are you sure you want to remove &ldquo;
            {creatorsList.find((creator) => creator.id === selected[0])?.name}
            &ldquo;?
          </Typography>
        ) : (
          <Typography>
            Are you sure you want to remove {selected.length} creators?
          </Typography>
        )
      }
      open={isOpen}
      onClose={onClose}
      actions={
        <>
          <Button onClick={onClose} variant="text" text="cancel" />
          <Button
            onClick={handleSubmit}
            variant="contained"
            text="remove"
            color="error"
            loading={isSubmitting}
          />
        </>
      }
    />
  );
};

export default RemoveCreatorFromGroup;
