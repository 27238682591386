import { AxiosPromise } from 'axios';

import { Violations } from '@/models/violations';

import { api, decomposeParams } from '.';

export const getViolations = (
  params?: Violations.ViolationParams
): AxiosPromise<Violations.List> =>
  api.get(`/v2/violations${decomposeParams(params)}`);
