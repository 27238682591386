import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Skeleton } from '@mui/material';
import { GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';

import Page from '@/components/Page';
import Button from '@/components/button';
import DataTable from '@/components/dataTable';
import Header from '@/components/header';
import Toolbar from '@/components/toolbar/Toolbar';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants/table';
import { useFetchKeywordGroupById } from '@/hooks/queries/keywords';
import { getPathWithLocalParams } from '@/hooks/urlParams';
import useTableParams from '@/hooks/useTableParams';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { Keywords as KeywordsModel } from '@/models/keywords';
import { getPrettyDate } from '@/utils/datesAndTime';
import getRoute from '@/utils/getRoute';

import Error from '../../misc/error';
import AddKeywordToGroup from './_partials/AddKeywordsDialog';
import DeleteKeywordsDialog from './_partials/DeleteKeywordsDialog';

const defaultParams = {
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE,
  sort: undefined,
  order: undefined,
  name: ''
};

const Keywords = () => {
  const id = Number(useParams().id);
  const navigate = useWorkspaceNavigate();

  const { params, handlers } = useTableParams(
    getRoute.keywords.DETAIL(id),
    defaultParams
  );

  const [selectedKeywords, setSelectedKeywords] =
    useState<GridRowSelectionModel>([]);
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [isAddDialogOpen, setAddDialogOpen] = useState(false);

  const {
    data: keywordGroup,
    isLoading,
    isError
  } = useFetchKeywordGroupById(id);

  const keywordsList = useMemo(
    () =>
      keywordGroup?.keywords?.filter((data) =>
        data.value?.toLowerCase().includes((params.name ?? '').toLowerCase())
      ),
    [keywordGroup, params.name]
  );

  const headers = [
    {
      field: 'value',
      headerName: 'Keyword',
      flex: 1,
      width: 160,
      renderCell: (params: GridRenderCellParams<KeywordsModel.Keyword>) =>
        params.row.value
    },
    {
      field: 'createdAt',
      headerName: 'Created',
      flex: 1,
      width: 140,
      renderCell: (params: GridRenderCellParams<KeywordsModel.Keyword>) =>
        getPrettyDate(params.row.createdAt)
    }
  ];

  if (isError) {
    return <Error message="Failed to get keyword group details" />;
  }

  return (
    <>
      <Page
        title="Keywords"
        header={
          <Header
            isSmallHeader
            actions={
              <Button
                onClick={() => setAddDialogOpen(true)}
                text="Add keyword"
                size="small"
              />
            }
            onClickReturn={() =>
              navigate(getPathWithLocalParams(getRoute.keywords.LIST()))
            }
            breadcrumbsList={[
              {
                name: 'Keyword Group',
                onClick: () =>
                  navigate(getPathWithLocalParams(getRoute.keywords.LIST()))
              }
            ]}
            title={keywordGroup?.title || <Skeleton width={200} />}
          />
        }
        toolbar={
          <Toolbar
            isDeleteEnabled={selectedKeywords.length > 0}
            onClickDelete={() => setDeleteDialogOpen(true)}
            onChangeSearch={handlers.onChangeSearch}
            showDeleteButton
            showSearchInput
            searchValue={params.name ? String(params.name) : ''}
            totalItems={keywordGroup?.keywords.length || 0}
          />
        }
      >
        <DataTable
          rowSelectionModel={selectedKeywords}
          onRowSelectionModelChange={setSelectedKeywords}
          rowHeight={80}
          rows={keywordsList || []}
          columns={headers}
          loading={isLoading}
          checkboxSelection
          onChangePageSize={handlers.onChangePageSize}
          onChangePage={handlers.onChangePage}
          onSortModelChange={handlers.onSortModelChange}
          sort={params.sort}
          order={params.order}
          page={params.page}
          size={params.size}
        />
      </Page>
      {isDeleteDialogOpen && (
        <DeleteKeywordsDialog
          isOpen={isDeleteDialogOpen}
          onClose={() => setDeleteDialogOpen(false)}
          keywordsList={keywordsList}
          selectedKeywords={selectedKeywords}
        />
      )}
      {isAddDialogOpen && (
        <AddKeywordToGroup
          isOpen={isAddDialogOpen}
          onClose={() => setAddDialogOpen(false)}
        />
      )}
    </>
  );
};

export default Keywords;
