import { useEffect } from 'react';

type ArrowKeyNavigationOptions = {
  onLeft?: () => void;
  onRight?: () => void;
  onUp?: () => void;
  onDown?: () => void;
};

/**
 * A reusable hook to handle arrow key navigation.
 * @param options - Callbacks for arrow key events.
 */
const useArrowKeyNavigation = ({
  onLeft,
  onRight,
  onUp,
  onDown
}: ArrowKeyNavigationOptions) => {
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent) => {
      switch (event.key) {
        case 'ArrowLeft':
          onLeft?.();
          break;
        case 'ArrowRight':
          onRight?.();
          break;
        case 'ArrowUp':
          onUp?.();
          break;
        case 'ArrowDown':
          onDown?.();
          break;
        default:
          break;
      }
    };

    window.addEventListener('keydown', handleKeyDown);

    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [onLeft, onRight, onUp, onDown]); // Re-run effect if any callback changes
};

export default useArrowKeyNavigation;
