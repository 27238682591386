import { Paper, Tooltip } from '@mui/material';

import { Posts } from '@/models/posts';

interface PostIdentificationMarkersProps {
  items?: Array<Posts.MinorItem | Posts.OcrAdvImageItem>;
  dimensions: {
    width: number;
    height: number;
  };
}

/**
 * Computes the offsets and rendered dimensions of media (image or video) centered within its container.
 *
 * This function works with both images and videos using consistent container IDs for reliable targeting.
 * It calculates the rendered dimensions and positioning offsets needed for accurate placement of
 * overlay elements like markers on both types of media.
 *
 * Returns:
 *   An object containing:
 *     - `leftGap`: The horizontal gap (in pixels) on the left side of the centered media.
 *     - `renderedWidth`: The width (in pixels) of the rendered media inside the container.
 *     - `renderedHeight`: The height (in pixels) of the rendered media inside the container.
 *     - `topGap`: The vertical gap (in pixels) at the top of the container.
 */
const calculateMediaOffset = () => {
  // First check for an image container
  const imageContainer = document.getElementById('image-container');
  if (imageContainer) {
    const image = imageContainer.querySelector('img');
    if (image) {
      const containerWidth = imageContainer.clientWidth;
      const containerHeight = imageContainer.clientHeight;
      const { naturalWidth, naturalHeight } = image;
      const imageAspectRatio = naturalWidth / naturalHeight;
      const renderedHeight = containerHeight;
      const renderedWidth = containerHeight * imageAspectRatio;
      const leftGap = (containerWidth - renderedWidth) / 2;
      return { leftGap, renderedWidth, renderedHeight, topGap: 0 };
    }
  }

  // Then check for our video container by ID
  const videoContainer = document.getElementById('video-container');
  if (videoContainer) {
    const containerWidth = videoContainer.clientWidth;
    const containerHeight = videoContainer.clientHeight;

    // Find the actual player within the container
    const playerElement =
      videoContainer.querySelector('.react-player') ||
      videoContainer.querySelector('video') ||
      videoContainer;

    if (!playerElement)
      return { leftGap: 0, renderedWidth: 0, renderedHeight: 0, topGap: 0 };

    const playerWidth = playerElement.clientWidth;
    const playerHeight = playerElement.clientHeight;

    // Get the video's aspect ratio if available
    const videoAspectRatio = parseFloat(
      videoContainer.getAttribute('data-video-aspect') || '0'
    );

    // Calculate actual video dimensions and gaps
    let renderedWidth = playerWidth;
    let renderedHeight = playerHeight;
    let topGap = 0;
    let leftGap = 0;

    if (videoAspectRatio > 0) {
      const containerAspectRatio = playerWidth / playerHeight;

      if (videoAspectRatio > containerAspectRatio) {
        // Video is wider than container - will have black bars on top and bottom
        renderedWidth = playerWidth;
        renderedHeight = playerWidth / videoAspectRatio;
        topGap = (playerHeight - renderedHeight) / 2;
        leftGap = 0;
      } else {
        // Video is taller than container - will have black bars on sides
        renderedHeight = playerHeight;
        renderedWidth = playerHeight * videoAspectRatio;
        leftGap = (playerWidth - renderedWidth) / 2;
        topGap = 0;
      }
    } else {
      // Fallback if we can't determine aspect ratio
      // Just use the player dimensions and hope for the best
      leftGap = (containerWidth - playerWidth) / 2;
      topGap = (containerHeight - playerHeight) / 2;
    }

    return {
      leftGap,
      renderedWidth,
      renderedHeight,
      topGap
    };
  }

  // Return default values if no media container is found
  return { leftGap: 0, renderedWidth: 0, renderedHeight: 0, topGap: 0 };
};
const PostIdentificationMarkers = ({
  items = []
}: PostIdentificationMarkersProps) => {
  const color = 'yellow';

  const overlayStyle = (item: Posts.MinorItem | Posts.OcrAdvImageItem) => {
    const { topGap, leftGap, renderedWidth, renderedHeight } =
      calculateMediaOffset();

    return {
      width: item.coordinates.width * renderedWidth,
      height: item.coordinates.height * renderedHeight,
      left: item.coordinates.left * renderedWidth + leftGap,
      top: item.coordinates.top * renderedHeight + topGap,
      transform: `rotate(${item.coordinates.angle || 0}deg)`, // Apply rotation
      transformOrigin: 'top left' // Rotate around the top-left corner
    };
  };

  return (
    <>
      {items.map((item, index) => (
        <Tooltip
          open={true}
          arrow
          title={
            'ageRange' in item && `${item.ageRange.low}-${item.ageRange.high}`
          }
          key={index}
          placement="top"
          disableHoverListener
        >
          <Paper
            id={`identification-marker-${index}`}
            elevation={3}
            sx={{
              ...overlayStyle(item),
              position: 'absolute',
              borderRadius: 0,
              border: `1px solid ${color}`,
              backgroundColor: 'transparent'
            }}
          >
            <div
              style={{
                width: '100%',
                height: '100%',
                backgroundColor: color,
                opacity: 0.1
              }}
            />
          </Paper>
        </Tooltip>
      ))}
    </>
  );
};

export default PostIdentificationMarkers;
