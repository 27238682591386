import {
  ArrowForward,
  CheckCircleRounded,
  Error,
  Warning
} from '@mui/icons-material';
import {
  Link,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography
} from '@mui/material';

import Button from '@/components/button';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { Violations } from '@/models/violations';
import { formatDate } from '@/utils/datesAndTime';
import getRoute from '@/utils/getRoute';

interface DetailPanelProps {
  row: Violations.ViolationRecord;
  onReviewClick: (id: number) => void;
}

// Define types for compliance status and ad type
type ComplianceStatus = 'compliant' | 'questionable' | 'violation' | 'unknown';
type AdType =
  | 'questionable'
  | 'sponsored'
  | 'promoted'
  | string
  | null
  | undefined;

const complianceMapper: Record<
  ComplianceStatus,
  { icon: React.ReactNode; text: string }
> = {
  compliant: {
    icon: <CheckCircleRounded color="success" />,
    text: 'Compliant'
  },
  questionable: {
    icon: <Warning color="warning" />,
    text: 'Questionable'
  },
  violation: {
    icon: <Error color="error" />,
    text: 'Violation'
  },
  unknown: {
    icon: <></>,
    text: ''
  }
};

// Format ad type for display
const formatAdType = (adType: AdType): React.ReactNode => {
  if (!adType) {
    return <>—</>;
  }

  if (adType === 'questionable') {
    return <Typography>Possible Ad</Typography>;
  }

  return (
    <Typography>{adType.charAt(0).toUpperCase() + adType.slice(1)}</Typography>
  );
};

const DetailPanel = ({ row, onReviewClick }: DetailPanelProps) => {
  const navigate = useWorkspaceNavigate();
  const getComplianceInfo = (compliance: string) =>
    complianceMapper[compliance as ComplianceStatus] ||
    complianceMapper.unknown;

  return (
    <Stack alignItems="end">
      <TableContainer component={Paper} sx={{ backgroundColor: 'transparent' }}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Evaluation</TableCell>
              <TableCell>Time of rating</TableCell>
              <TableCell>Compliance</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>User</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {row.violations.map((violation) => (
              <TableRow
                key={violation.evaluationName}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                <TableCell
                  onClick={() =>
                    navigate(
                      getRoute.evaluations.DETAIL(violation.evaluationId)
                    )
                  }
                >
                  <Link
                    underline="hover"
                    sx={{ cursor: 'pointer' }}
                    onClick={(event) => {
                      event.preventDefault(); // Prevent default link behavior
                      navigate(
                        getRoute.evaluations.DETAIL(violation.evaluationId)
                      );
                    }}
                    color="primary"
                  >
                    <Typography>{violation.evaluationName}</Typography>
                  </Link>
                </TableCell>
                <TableCell>
                  {formatDate(
                    new Date(violation.createdAt),
                    'dd/MM/yyyy HH:mm'
                  )}
                </TableCell>
                <TableCell>
                  <Stack direction="row" alignItems="center" gap="4px">
                    {getComplianceInfo(violation.compliance).icon}
                    <Typography>
                      {getComplianceInfo(violation.compliance).text}
                    </Typography>
                  </Stack>
                </TableCell>
                <TableCell>
                  {formatAdType(violation.adType as AdType)}
                </TableCell>
                <TableCell>
                  <Typography>
                    @{violation.author}
                  </Typography>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Button
        disabled
        onClick={() => onReviewClick(row.postId)}
        sx={{ width: 'fit-content', marginX: 2, marginY: 1 }}
        text="Review violation"
        size='small'
        endIcon={<ArrowForward />}
      />
    </Stack>
  );
};

export default DetailPanel;
