import { useState, useMemo, useCallback, useRef, useEffect } from 'react';
import { NavLink as RouterLink, useParams } from 'react-router-dom';

import { FileCopy, ImageSearch } from '@mui/icons-material';
import Add from '@mui/icons-material/Add';
import CalendarViewMonthIcon from '@mui/icons-material/CalendarViewMonth';
import ViewListOutlinedIcon from '@mui/icons-material/ViewListOutlined';
import {
  Box,
  CardActionArea,
  Grid,
  Skeleton,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  useTheme
} from '@mui/material';
import {
  getGridDateOperators,
  getGridNumericOperators,
  getGridSingleSelectOperators,
  GridActionsCellItem,
  GridFilterModel,
  GridRenderCellParams,
  GridToolbar,
  GridValueFormatterParams
} from '@mui/x-data-grid';

import { getEvaluation } from '@/api/evaluations';
import Page from '@/components/Page';
import Button from '@/components/button';
import Card from '@/components/card';
import DataTable from '@/components/dataTable';
import NoRowsOverlay from '@/components/dataTable/customNoRowsOverlay/NoRowsOverlay';
import OnBoardingOverlay from '@/components/dataTable/customNoRowsOverlay/OnBoardingOverlay';
import Dialog from '@/components/dialog';
import Header from '@/components/header';
import Toolbar from '@/components/toolbar';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants/table';
import {
  useDeleteEvaluation,
  useFetchEvaluations
} from '@/hooks/queries/evaluations';
import { useAppSelector } from '@/hooks/redux';
import useAlert from '@/hooks/useAlert';
import useTableParams from '@/hooks/useTableParams';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { Evaluation } from '@/models/evaluations';
import LocalStorageService from '@/services/LocalStorageService';
import { getDateWithTime, getPrettyDate } from '@/utils/datesAndTime';
import getRoute from '@/utils/getRoute';
import nFormatter from '@/utils/nFormatter';
import Error from '@/views/misc/error';

import CreateEvaluationDialog from '../create';

interface FilterItem {
  field: string;
  operator: string;
  value: any;
}

interface FilterModel {
  items: FilterItem[];
}

const EvaluationsOnBoardingOverlay = () => (
  <OnBoardingOverlay
    icon={<ImageSearch color="action" sx={{ width: 40, height: 40 }} />}
    title="Start list of Evaluations"
    text="Create an evaluation to start analising social media posts"
  />
);

const defaultParams = {
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE,
  sort: undefined,
  order: undefined,
  name: undefined
};

const EvaluationsList = () => {
  const theme = useTheme();
  const alert = useAlert();
  const navigate = useWorkspaceNavigate();
  const workspaceId = Number(useParams<{ workspaceId: string }>().workspaceId);
  const { params, handlers } = useTableParams(
    getRoute.evaluations.LIST(),
    defaultParams
  );

  const user = useAppSelector((state) => state.user.user);

  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState<boolean>(false);
  const [isDeleteDialogOpen, setDeleteIsDialogOpen] = useState<boolean>(false);
  const [selectedEvaluations, setSelectedEvaluations] = useState<number[]>([]);
  const [selectedView, setSelectedView] = useState<string>(
    LocalStorageService.getItem('evaluations-list-view') || 'table'
  );
  const [cardViewPage, setCardViewPage] = useState<number>(1);
  const endOfListRef = useRef<HTMLDivElement | null>(null); // Ref for the end of the list

  const evaluationCopy = useRef<any>(undefined);

  const [filterModel, setFilterModel] = useState<FilterModel>({
    items: []
  });

  const {
    data: evaluations = [],
    isLoading,
    refetch,
    isError
  } = useFetchEvaluations();

  const filteredEvaluations = useMemo(() => {
    // First apply the name/title filter
    let filtered = evaluations.filter((evaluation) =>
      evaluation.title
        .toLowerCase()
        .includes(params.name ? params.name.toLowerCase() : '')
    );

    // Then apply each filter from the filter model
    if (filterModel.items.length > 0) {
      filtered = filtered.filter((evaluation) =>
        // Check if the evaluation satisfies all filter conditions
        filterModel.items.every((filter) => {
          const { field, operator, value } = filter;

          // Skip applying this filter if value is empty/undefined/null
          if (value === undefined || value === null || value === '') {
            return true; // Return true to keep all rows when no value is provided
          }

          // Handle different types of filters
          switch (field) {
            case 'authorName':
              // Handle single select
              if (operator === 'is') {
                return evaluation.authorName === value;
              }

              if (operator === 'not') {
                return evaluation.authorName !== value;
              }
              if (operator === 'isAnyOf') {
                return Array.isArray(value)
                  ? value.includes(evaluation.authorName)
                  : false;
              }
              return true;

            case 'createdAt':
            case 'updatedAt': {
              // Skip filtering if value is empty
              if (!value) {
                return true;
              }

              const dateToCheck =
                field === 'createdAt'
                  ? new Date(evaluation.createdAt)
                  : new Date(evaluation.updatedAt || evaluation.createdAt);

              // Make sure value is converted to a Date object
              // This handles when value is a string like "2023-01-01"
              let compareDate;
              try {
                // If value is already a Date object or a valid date string
                compareDate = new Date(value);

                // Check if we got a valid date
                if (Number.isNaN(compareDate.getTime())) {
                  return true; // Skip invalid dates
                }
              } catch (e) {
                // If we can't parse the date for any reason, skip this filter
                return true;
              }

              // Remove time part from dates
              dateToCheck.setHours(0, 0, 0, 0);
              compareDate.setHours(0, 0, 0, 0);

              // Handle date operators
              switch (operator) {
                case 'is':
                  return (
                    dateToCheck.toDateString() === compareDate.toDateString()
                  );
                case 'not':
                  return (
                    dateToCheck.toDateString() !== compareDate.toDateString()
                  );
                case 'after':
                  return dateToCheck > compareDate;
                case 'onOrAfter':
                  return dateToCheck >= compareDate;
                case 'before':
                  return dateToCheck < compareDate;
                case 'onOrBefore':
                  return dateToCheck <= compareDate;
                default:
                  return true;
              }
            }
            case 'totalItems': {
              // Handle numeric filters
              const { totalItems } = evaluation;
              const numValue = Number(value);

              switch (operator) {
                case '=':
                  return totalItems === numValue;
                case '!=':
                  return totalItems !== numValue;
                case '>':
                  return totalItems > numValue;
                case '>=':
                  return totalItems >= numValue;
                case '<':
                  return totalItems < numValue;
                case '<=':
                  return totalItems <= numValue;
                default:
                  return true;
              }
            }

            default:
              return true;
          }
        })
      );
    }

    return filtered;
  }, [evaluations, params.name, filterModel.items]);

  const handleFilterModelChange = (model: GridFilterModel) => {
    setFilterModel({ items: model.items as FilterItem[] });
  };

  // Memoized authors list for the filter
  const authors = useMemo(
    () =>
      Array.from(
        new Set(evaluations.map((evaluation) => evaluation.authorName))
      ).map((author) => ({ value: author, label: author })),
    [evaluations]
  );

  const { mutate: deleteEvaluation, isPending: isDeleting } =
    useDeleteEvaluation(
      () => {
        const firstSelectedEvaluationName = filteredEvaluations.find(
          (evaluation) => evaluation.id === selectedEvaluations[0]
        )?.title;

        const alertMessage =
          selectedEvaluations.length === 1
            ? `"${firstSelectedEvaluationName}" has been successfully deleted`
            : `${selectedEvaluations.length} evaluations have been successfully deleted`;

        alert.info(alertMessage);
        handleCloseDeleteDialog(true);
        refetch();
      },
      () => alert.error(`Some error occurred while deleting the evaluations`)
    );

  const handleCloseCreateDialog = () => {
    setIsCreateDialogOpen(false);
    evaluationCopy.current = undefined;
  };

  const handleCloseDeleteDialog = (clearSelection = false) => {
    setDeleteIsDialogOpen(false);
    if (clearSelection) setSelectedEvaluations([]);
  };

  const handleDeleteEvaluations = async (
    e: React.MouseEvent<HTMLButtonElement>
  ) => {
    e.preventDefault();
    deleteEvaluation({ ids: selectedEvaluations });
  };

  const handleCopyEvaluation = (id: number) => {
    getEvaluation(id)
      .then(({ data }) => {
        evaluationCopy.current = data;
        setIsCreateDialogOpen(true);
      })
      .catch(() =>
        alert.error('Something went wrong while copying the evaluation')
      );
  };

  const handleChangeView = (
    _: React.MouseEvent<HTMLElement>,
    newView: string
  ) => {
    if (newView !== null) {
      // Prevent deselection
      setSelectedView(newView);
      setSelectedEvaluations([]);
      LocalStorageService.setItem('evaluations-list-view', newView);
    }
  };

  const columns = useMemo(
    () => [
      {
        field: 'title',
        headerName: 'Evaluation Title',
        width: 100,
        flex: 1,
        filterable: false,
        renderCell: (data: GridRenderCellParams<Evaluation.ListItem>) => {
          const { title } = data.row;

          return (
            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {title}
            </Typography>
          );
        }
      },
      {
        field: 'createdAt',
        headerName: 'Creation Date',
        width: 100,
        flex: 1,
        filterable: true,
        filterOperators: getGridDateOperators().filter(
          (operator) =>
            operator.value !== 'isEmpty' && operator.value !== 'isNotEmpty'
        ),
        valueFormatter: (
          params: GridValueFormatterParams<Evaluation.ListItem>
        ) => getDateWithTime(params.value.toString()),
        valueGetter: (params: GridRenderCellParams<Evaluation.ListItem>) => {
          const { createdAt } = params.row;
          return new Date(createdAt);
        },
        renderCell: (data: GridRenderCellParams<Evaluation.ListItem>) => {
          const { createdAt } = data.row;
          return <Typography>{getPrettyDate(createdAt)}</Typography>;
        }
      },
      {
        field: 'updatedAt',
        headerName: 'Last Modified',
        width: 100,
        flex: 1,
        filterable: true,
        filterOperators: getGridDateOperators().filter(
          (operator) =>
            operator.value !== 'isEmpty' && operator.value !== 'isNotEmpty'
        ),
        valueFormatter: (
          params: GridValueFormatterParams<Evaluation.ListItem>
        ) => getDateWithTime(params.value.toString()),
        valueGetter: (params: GridRenderCellParams<Evaluation.ListItem>) => {
          const { createdAt } = params.row;
          return new Date(createdAt);
        },
        renderCell: (data: GridRenderCellParams<Evaluation.ListItem>) => {
          const { updatedAt, createdAt } = data.row;
          return (
            <Typography>{getPrettyDate(updatedAt || createdAt)}</Typography>
          );
        }
      },
      {
        field: 'authorName',
        headerName: 'Author',
        width: 100,
        flex: 1,
        filterable: true,
        type: 'singleSelect',
        valueOptions: authors,
        filterOperators: getGridSingleSelectOperators(),
        renderCell: (data: GridRenderCellParams<Evaluation.ListItem>) => {
          const { authorName } = data.row;
          return <Typography>{authorName}</Typography>;
        }
      },
      {
        field: 'totalItems',
        headerName: 'Total Items',
        minWidth: 150,
        type: 'number',
        filterable: true,
        filterOperators: getGridNumericOperators().filter(
          (operator) =>
            operator.value !== 'isEmpty' &&
            operator.value !== 'isNotEmpty' &&
            operator.value !== 'isAnyOf'
        ),
        valueFormatter: (
          params: GridValueFormatterParams<Evaluation.ListItem>
        ) => params.value,
        renderCell: (data: GridRenderCellParams<Evaluation.ListItem>) => {
          const { totalItems } = data.row;
          return <Typography>{nFormatter(totalItems)}</Typography>;
        }
      },
      {
        field: 'actions',
        type: 'actions',
        width: 20,
        getActions: (data: GridRenderCellParams<Evaluation.ListItem>) => [
          <GridActionsCellItem
            icon={<FileCopy />}
            key={`copy-${data.row.id}`}
            label="Copy Evaluation"
            showInMenu
            onClick={() => handleCopyEvaluation(data.row.id)}
          />
        ]
      }
    ],
    [authors]
  );

  const isDeleteButtonDisabled = selectedEvaluations?.length <= 0;

  const gridItems = Array.from({ length: 18 }, (_, index) => index + 1);

  const numberOfVisibleCards =
    cardViewPage * 9 - (evaluations.length === 0 ? 1 : 0);

  // UseEffect to add event listener for scroll
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + window.scrollY >= document.body.offsetHeight &&
        !isLoading
      ) {
        setCardViewPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [isLoading]);

  // Ref callback for the end of the list
  const handleEndOfListRef = useCallback(
    (node: HTMLDivElement | null) => {
      if (isLoading) return;
      if (endOfListRef.current) return;

      const observer = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setCardViewPage((prevPage) => prevPage + 1);
        }
      });

      if (node) observer.observe(node);

      endOfListRef.current = node;
    },
    [isLoading]
  );

  let emptyStateEvaluations = EvaluationsOnBoardingOverlay;

  if (params.name !== undefined) {
    emptyStateEvaluations = () => <NoRowsOverlay />;
  }

  if (isError)
    return (
      <Page>
        <Error message="Failed to get evaluations" />
      </Page>
    );

  return (
    <>
      <Page
        title="Evaluations"
        header={
          <Header
            actions={
              <>
                <ToggleButtonGroup
                  value={selectedView}
                  exclusive
                  onChange={handleChangeView}
                  aria-label="view"
                  size="small"
                  sx={{
                    backgroundColor: theme.palette.background.paper
                  }}
                >
                  <ToggleButton
                    value="card"
                    aria-label="card view"
                    disabled={selectedView === 'card'}
                  >
                    <CalendarViewMonthIcon />
                  </ToggleButton>
                  <ToggleButton
                    value="table"
                    aria-label="table view"
                    disabled={selectedView === 'table'}
                  >
                    <ViewListOutlinedIcon />
                  </ToggleButton>
                </ToggleButtonGroup>
                <Button
                  onClick={() => setIsCreateDialogOpen(true)}
                  text="create evaluation"
                />
                {isCreateDialogOpen && (
                  <CreateEvaluationDialog
                    onClose={handleCloseCreateDialog}
                    evaluationCopy={evaluationCopy.current}
                  />
                )}
              </>
            }
            title="Evaluations"
          />
        }
        toolbar={
          <Toolbar
            isDeleteEnabled={selectedEvaluations.length > 0}
            onClickDelete={() => setDeleteIsDialogOpen(true)}
            onChangeSearch={handlers.onChangeSearch}
            showDeleteButton={!isDeleteButtonDisabled}
            showSearchInput
            searchValue={params.name ? String(params.name) : ''}
            totalItems={filteredEvaluations.length || 0}
          />
        }
      >
        <>
          {selectedView === 'card' && isLoading && (
            <Grid container spacing={2}>
              {gridItems.map((number) => (
                <Grid item xs={12} sm={6} md={4} key={number}>
                  <Skeleton variant="rectangular" height={176} />
                </Grid>
              ))}
            </Grid>
          )}

          {selectedView === 'card' &&
            !isLoading &&
            evaluations.length !== 0 && (
              <>
                <Grid container spacing={2}>
                  {filteredEvaluations
                    .slice(0, numberOfVisibleCards)
                    .map((evaluation) => (
                      <Grid item xs={12} sm={6} md={4} key={evaluation.id}>
                        <CardActionArea
                          component={RouterLink}
                          to={`/workspace/${workspaceId}${getRoute.evaluations.DETAIL(
                            evaluation.id
                          )}`}
                        >
                          <Card
                            title={
                              <Typography variant="h6">
                                {evaluation.title}
                              </Typography>
                            }
                            elevation={3}
                            titleEllipsis
                            content={
                              <Stack gap={0.5}>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  {evaluation.totalItems} items -{' '}
                                  {evaluation.updatedAt
                                    ? getPrettyDate(evaluation.updatedAt)
                                    : getPrettyDate(evaluation.createdAt)}
                                </Typography>
                                <Typography
                                  variant="body2"
                                  color="textSecondary"
                                >
                                  Created by {evaluation.authorName}
                                </Typography>
                              </Stack>
                            }
                            actions={
                              <>
                                {evaluation.authorId === user.id && (
                                  <Button
                                    color="error"
                                    variant="text"
                                    text="delete"
                                    onClick={(e) => {
                                      e.preventDefault();
                                      setDeleteIsDialogOpen(true);
                                      setSelectedEvaluations([evaluation.id]);
                                    }}
                                  />
                                )}
                                <Button
                                  variant="text"
                                  text="copy"
                                  onClick={(e) => {
                                    e.preventDefault();
                                    handleCopyEvaluation(evaluation.id);
                                  }}
                                />
                              </>
                            }
                          />
                        </CardActionArea>
                      </Grid>
                    ))}
                </Grid>
                <div ref={handleEndOfListRef} />
              </>
            )}

          {selectedView === 'card' &&
            !isLoading &&
            evaluations.length === 0 && (
              <>
                <Grid container spacing={2}>
                  <Grid item xs={12} sm={6} md={4}>
                    <CardActionArea>
                      <Stack
                        sx={{
                          boxShadow:
                            '0px 3px 3px -2px rgba(0,0,0,0.2), 0px 3px 4px 0px rgba(0,0,0,0.14), 0px 1px 8px 0px rgba(0,0,0,0.12)',
                          background: theme.palette.primary.main,
                          height: '176px'
                        }}
                        alignItems="center"
                        justifyContent="center"
                        onClick={() => setIsCreateDialogOpen(true)}
                      >
                        <Stack
                          alignItems="center"
                          justifyContent="center"
                          gap={1}
                        >
                          <Add
                            sx={{ color: theme.palette.primary.contrastText }}
                          />
                          <Typography
                            sx={{ color: theme.palette.primary.contrastText }}
                            color="primary"
                            variant="subtitle2"
                          >
                            CREATE EVALUATION
                          </Typography>
                        </Stack>
                      </Stack>
                    </CardActionArea>
                  </Grid>
                  {gridItems.slice(0, numberOfVisibleCards).map((id) => (
                    <Grid item xs={12} sm={6} md={4} key={id}>
                      <Box
                        sx={{
                          border: '1px dashed rgba(0, 0, 0, 0.18);',
                          boxShadow: '0px 2px 4px -1px rgba(0, 0, 0, 0.15)',
                          filter:
                            'drop-shadow(0px 3px 5px rgba(0, 0, 0, 0.13)) drop-shadow(0px 1px 9px rgba(0, 0, 0, 0.12))'
                        }}
                        width={373}
                        height={176}
                      />
                    </Grid>
                  ))}
                </Grid>
              </>
            )}

          {selectedView === 'table' && (
            <DataTable
              checkboxSelection
              columns={columns}
              onRowSelectionModelChange={(newSelection) =>
                setSelectedEvaluations(newSelection as number[])
              }
              rowHeight={72}
              rows={filteredEvaluations}
              rowSelectionModel={selectedEvaluations}
              loading={isLoading}
              onRowClick={(row) =>
                navigate(getRoute.evaluations.DETAIL(row.id))
              }
              isRowSelectable={(data) => data.row.authorId === user.id}
              rowCount={filteredEvaluations.length}
              onChangePageSize={handlers.onChangePageSize}
              onChangePage={handlers.onChangePage}
              onSortModelChange={handlers.onSortModelChange}
              filterModel={filterModel}
              onFilterModelChange={(model) => handleFilterModelChange(model)}
              sort={params.sort}
              order={params.order}
              page={params.page}
              size={params.size}
              toolbar={GridToolbar}
              noRowsOverlay={emptyStateEvaluations}
            />
          )}
        </>
      </Page>
      <Dialog
        title="Delete Evaluation"
        dialogContent={
          selectedEvaluations.length === 1 ? (
            <>
              <Typography>
                Are you sure you want to delete &ldquo;
                {
                  filteredEvaluations.find(
                    (item) => item.id === selectedEvaluations[0]
                  )?.title
                }
                &ldquo;?
              </Typography>
              <Typography>
                You will not be able to recover this evaluation.
              </Typography>
            </>
          ) : (
            <>
              <Typography>
                Are you sure you want to delete {selectedEvaluations.length}{' '}
                evaluations?
              </Typography>
              <Typography>You will not be able to recover these.</Typography>
            </>
          )
        }
        open={isDeleteDialogOpen}
        onClose={() => handleCloseDeleteDialog(false)}
        actions={
          <>
            <Button
              variant="text"
              text="cancel"
              onClick={() => handleCloseDeleteDialog(false)}
              disabled={isDeleting}
            />
            <Button
              color="error"
              text="delete"
              onClick={handleDeleteEvaluations}
              loading={isDeleting}
            />
          </>
        }
      />
    </>
  );
};

export default EvaluationsList;
