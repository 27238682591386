import { useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';

import { Box, Skeleton, Typography } from '@mui/material';
import { GridRenderCellParams, GridRowSelectionModel } from '@mui/x-data-grid';

import Page from '@/components/Page';
import Avatar from '@/components/avatar/Avatar';
import Button from '@/components/button/Button';
import DataTable from '@/components/dataTable/DataTable';
import ChipsColumn from '@/components/dataTable/customChipsColumn';
import Header from '@/components/header/Header';
import Toolbar from '@/components/toolbar';
import { DEFAULT_PAGE, DEFAULT_PAGE_SIZE } from '@/constants/table';
import { useFetchCreatorGroup } from '@/hooks/queries/creatorGroups';
import { getPathWithLocalParams } from '@/hooks/urlParams';
import { useDebounce } from '@/hooks/useDebounce';
import useTableParams from '@/hooks/useTableParams';
import useWorkspaceNavigate from '@/hooks/useWorkspaceNavigate';
import { CreatorGroups } from '@/models/creator';
import { getPrettyDate } from '@/utils/datesAndTime';
import getRoute from '@/utils/getRoute';
import nFormatter from '@/utils/nFormatter/nFormatter';
import Error from '@/views/misc/error';

import AddCreator from './_partials/AddCreatorToGroup';
import RemoveCreator from './_partials/RemoveCreatorFromGroup';

const defaultParams = {
  page: DEFAULT_PAGE,
  size: DEFAULT_PAGE_SIZE,
  sort: undefined,
  order: undefined,
  name: undefined
};

const CreatorGroupsDetail = () => {
  const id = Number(useParams().id);
  const navigate = useWorkspaceNavigate();

  const { params, handlers } = useTableParams(
    getRoute.creatorGroups.DETAIL(id, defaultParams),
    defaultParams
  );

  const [selected, setSelected] = useState<GridRowSelectionModel>([]);
  const [isOpenAddCreatorDialog, setIsOpenAddCreatorDialog] = useState(false);
  const [isOpenDeleteDialog, setIsOpenDeleteDialog] = useState(false);
  const deferredGroupsValue = useDebounce(params.name, 500);

  const {
    data: group,
    isLoading: isGroupLoading,
    isError
  } = useFetchCreatorGroup({
    id,
    params: {
      ...params,
      name: deferredGroupsValue ? String(deferredGroupsValue) : undefined
    }
  });

  const columns = useMemo(
    () => [
      {
        sortable: false,
        field: 's3AvatarPath',
        headerName: 'Avatar',
        renderCell: (data: GridRenderCellParams<CreatorGroups.DetailsItem>) => {
          const { name, avatarUrl } = data.row;

          return (
            <Box paddingLeft={1}>
              <Avatar src={avatarUrl} name={name} width="40px" fontSize={16} />
            </Box>
          );
        }
      },
      {
        sortable: false,
        field: 'name',
        headerName: 'Name',
        minWidth: 200,
        flex: 1,
        renderCell: (data: GridRenderCellParams<CreatorGroups.DetailsItem>) => {
          const { name } = data.row;

          return (
            <Typography sx={{ overflow: 'hidden', textOverflow: 'ellipsis' }}>
              {name}
            </Typography>
          );
        }
      },
      {
        sortable: false,
        field: 'platform',
        headerName: 'Platform',
        minWidth: 150,
        flex: 1,
        renderCell: (data: GridRenderCellParams<CreatorGroups.DetailsItem>) => {
          const { platforms } = data.row;

          const transformedArray = Object.entries(platforms)
            .filter(([, value]) => value === true)
            .map(([key]) => ({
              tooltip: '',
              keyword: key.charAt(0).toUpperCase() + key.slice(1)
            }));

          return (
            <ChipsColumn
              data={transformedArray}
              chipColor="default"
              cellWidth={data.colDef.computedWidth}
            />
          );
        }
      },
      {
        field: 'createdAt',
        headerName: 'Created',
        flex: 1,
        width: 140,
        renderCell: (data: GridRenderCellParams<CreatorGroups.DetailsItem>) =>
          getPrettyDate(data.row.createdAt)
      },
      {
        field: 'addedAt',
        headerName: 'Added',
        flex: 1,
        width: 140,
        renderCell: (data: GridRenderCellParams<CreatorGroups.DetailsItem>) =>
          getPrettyDate(data.row.addedAt)
      },
      {
        sortable: false,
        field: 'contentCount',
        headerName: 'Content Count',
        minWidth: 100,
        flex: 1,
        renderCell: (data: GridRenderCellParams<CreatorGroups.DetailsItem>) => {
          const { contentCount } = data.row;

          return <Typography>{nFormatter(contentCount)}</Typography>;
        }
      }
    ],
    [group?.results]
  );

  if (isError)
    return (
      <Page>
        <Error message="Failed to get the creator group details" />
      </Page>
    );

  return (
    <Page
      title="Creator Group"
      header={
        <Header
          isSmallHeader
          actions={
            <Button
              size="small"
              onClick={() => setIsOpenAddCreatorDialog(true)}
              text="Add Creators"
            />
          }
          onClickReturn={() =>
            navigate(getPathWithLocalParams(getRoute.creatorGroups.LIST()))
          }
          breadcrumbsList={[
            {
              name: 'Creator Groups',
              onClick: () =>
                navigate(getPathWithLocalParams(getRoute.creatorGroups.LIST()))
            }
          ]}
          title={
            isGroupLoading ? (
              <Skeleton variant="text" width="200px" />
            ) : (
              group?.name
            )
          }
        />
      }
      toolbar={
        <Toolbar
          isDeleteEnabled={selected.length > 0}
          onClickDelete={() => setIsOpenDeleteDialog(true)}
          onChangeSearch={handlers.onChangeSearch}
          showDeleteButton
          showSearchInput
          searchValue={params.name || ''}
          totalItems={group?.totalItems || 0}
        />
      }
    >
      <>
        <DataTable
          rowSelectionModel={selected}
          onRowSelectionModelChange={setSelected}
          onRowClick={(row) => navigate(getRoute.creators.DETAIL(row.id))}
          rowHeight={80}
          rows={group?.results || []}
          rowCount={group?.totalItems || 0}
          columns={columns}
          loading={isGroupLoading}
          checkboxSelection={true}
          paginationMode="server"
          filterMode="server"
          sortingMode="server"
          onChangePageSize={handlers.onChangePageSize}
          onChangePage={handlers.onChangePage}
          onSortModelChange={handlers.onSortModelChange}
          sort={params.sort}
          order={params.order}
          page={params.page}
          size={params.size}
        />
        {isOpenAddCreatorDialog && (
          <AddCreator
            isOpen={isOpenAddCreatorDialog}
            onClose={() => {
              setIsOpenAddCreatorDialog(false);
            }}
          />
        )}
        {isOpenDeleteDialog && (
          <RemoveCreator
            isOpen={isOpenDeleteDialog}
            onClose={() => setIsOpenDeleteDialog(false)}
            selected={selected}
            clearSelection={() => {
              setSelected([]);
            }}
            creatorsList={group?.results || []}
          />
        )}
      </>
    </Page>
  );
};

export default CreatorGroupsDetail;
